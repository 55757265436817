import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import styled from 'styled-components';
import * as postsActions from "../../../redux/actions/PostsActions";
import * as groupsActions from "../../../redux/actions/GroupsActions";
import { Input } from '../../ira/ui/Inputs';
import Select, {components} from 'react-select';
import { translate } from '../../../utils/Translate';
import NiceConfirm from '../../modals/NiceConfirm';

const POST_TYPES = {
    new_feature: {
        backgroundColor: '#c0e8f8',
        color: '#1b88b3',
        label: 'Нововведения'
    },
    bug_fixes: {
        backgroundColor: '#dff5d7',
        color: '#61b43e',
        label: 'Исправление багов'
    },
    module: {
        backgroundColor: '#dbeeff',
        color: '#1c6db4',
        label: 'Публикация модуля'
    },
    new_task: {
        backgroundColor: '#ffedd9',
        color: '#e69138',
        label: 'Новое задание'
    },
    group: {
        backgroundColor: '#b2fffb',
        color: '#068f88',
        label: 'Сообщение для группы'
    },
    admin: {
        backgroundColor: '#fdf6e4',
        color: '#e9b91d',
        label: 'Сообщение от администратора'
    }
}

const FILTER_OPTIONS = [
    {
        label: 'Все новости',
        value: 'all'
    },
    {
        label: 'Системные нововведения',
        value: 'new_feature'
    },
    {
        label: 'Исправления багов',
        value: 'bug_fixes'
    },
    {
        label: 'Публикация модулей',
        value: 'module'
    },
    {
        label: 'Сообщение от администратора',
        value: 'admin'
    },
    {
        label: 'Отложенные новости',
        value: 'postponed'
    },
]

const readMoreStyles = `
    cursor: pointer;
    margin-top: 10px;
    font-weight: bold;
`;

const CustomOption = ({ children, ...props }) => {
    return (
        <components.Option {...props}>{translate(props.data.value)}</components.Option>
    )
}

const CustomValue = ({ children, ...props }) => {
    return (
        <components.MultiValueLabel {...props}>{translate(props.data.value)}</components.MultiValueLabel>
    )
}
    

const addReadMore = (posts) => {
    for (let post of posts) {
        let element = document.getElementById(`post_content_${post.id}`);

        if (element && (element.offsetHeight < element.scrollHeight ||
            element.offsetWidth < element.scrollWidth)) {
            if (!document.getElementById(`read_more_${post.id}`)) {
                let readMore = document.createElement('p');

                readMore.textContent = 'Читать далее';
                readMore.id = 'read_more_' + post.id;
                readMore.style.cssText = readMoreStyles;
                readMore.onclick = function () {
                    element.classList.remove('line-clamp-6');
                    readMore.remove();
                };

                let item = document.getElementById(`post_item_${post.id}`);
                if (item) {
                    item.appendChild(readMore);
                }
            }
        }
    }
}

const filterPostTypes = (posts, types) => {
    types = types.reduce((arr, x) => arr.concat(x.value), []);
    return posts.filter(x => types.includes(x?.type) || !x.type && types.includes('all') || types.includes('admin'));
}

const hasGroup = (groups, post) => {
    for (let group of groups) {
        if (post?.postGroups?.includes(group?.id)) {
            return true;
        }
    }
    return false;
}

export default function AllPostsPanel(props) {
    const {
        tab,
    } = props;
    const dispatch = useDispatch();

    const {
        groups = [],
        currentUser,
        allPosts = [],
        isAdmin,
        loading
    } = useMappedState(useCallback(state => {
        let {currentUserId} = state.users;
        let currentUser = state.users.usersMap.get(currentUserId);

        let isAdmin = (currentUser?.isModerator == true);

        let groups = state.groups.groupsMap.toArray().sort((a, b) => {
            return (+b.timestamp - +a.timestamp);
        });

        let posts = state.posts.postsMap.toArray();
        posts = posts.sort((a, b) => (+b.timestamp - +a.timestamp));
        posts = posts.filter(a => {
            if (a.time == undefined || a.time < Date.now()) {
                if (a?.type == 'group' && hasGroup(groups, a)) {
                    return a;
                }
                if (a?.type != 'group') {
                    return a;
                }
            } else {
                if (a?.userId == currentUserId) {
                    return a;
                }
            }
        });

        return {
            groups: groups,
            currentUser: currentUser,
            allPosts: posts,
            loading: state.posts.loading,
            isAdmin: isAdmin
        }
    }, []));

    const [posts, setPosts] = useState(allPosts || []);
    const [query, setQuery] = useState('');
    const [filter, setFilter] = useState(FILTER_OPTIONS[0]);
    const [deleteModal, setDeleteModal] = useState(undefined);

    useEffect(() => {
        dispatch(postsActions.loadPosts());
        dispatch(groupsActions.loadAllGroups(currentUser?.id));
    }, []);

    useEffect(() => {
        setPosts(allPosts);

        return () => setPosts([]);
    }, [allPosts])

    useEffect(() => {
        let newPosts = allPosts;
        if (tab == 'my') {
            newPosts = newPosts.filter(x => x.userId == currentUser.id);
        }
        setPosts(newPosts);
        setFilter(FILTER_OPTIONS[0]);
        setQuery('');
    }, [tab])

    useEffect(() => {
        if (posts.length > 0) {
            addReadMore(posts);
        }
    }, [posts])

    useEffect(() => {
        if (query.length > 0) {
            let regex = RegExp(query, 'i');
            let newPosts = posts.filter(x => x.text.match(regex) || x.name.match(regex));
            setPosts(newPosts);
        } else {
            if (tab == 'my') {
                setPosts(allPosts.filter(x => x.userId == currentUser.id));
            } else {
                setPosts(allPosts);
            }
        }
    }, [query])

    // console.log('posts', posts);
    // console.log('currentUser', currentUser);

    return (
        <Wrapper>

            <Search>
                <Input
                    placeholder={translate('search')}
                    value={query}
                    onChange={(evt) => {
                        setQuery(evt.target.value);
                    }}
                />

                <Select
                    options={FILTER_OPTIONS}
                    isMulti={true}
                    value={filter}
                    components={{Option: CustomOption, MultiValueLabel: CustomValue}}
                    onChange={evt => {
                        setFilter(evt);
                        let newPosts = allPosts;
                        if (tab == 'my') {
                            newPosts = newPosts.filter(x => x.userId == currentUser.id);
                        }
                        setPosts(filterPostTypes(newPosts, evt));
                    }}
                />
            </Search>

            {posts.length > 0 ? null :
                <NoPosts>
                    {translate('no_news')}
                </NoPosts>
            }

            {posts.map((a, i) => {
                let type = POST_TYPES[a?.type || 'admin']

                return (
                    <PostItem key={a.id} id={`post_item_${a.id}`}>
                        <PostName>
                            <XName>
                                {a?.name}
                                {a.time == undefined || a.time < Date.now() ? null :
                                    <PostType type={{ backgroundColor: '#ffe7e7', color: '#d61414' }} style={{marginLeft: '5px', fontWeight: 'normal'}}>
                                        Не опубликовано
                                    </PostType>
                                }
                            </XName>
                            <PostNameRightPart>
                                <PostType type={type}>{type?.label}</PostType>
                                <span style={{fontSize: '12px', opacity: 0.5}}>
                                    {moment(a?.time || a.timestamp).format('DD.MM.YYYY HH:mm')}
                                </span>
                            </PostNameRightPart>
                        </PostName>
                        <PostContent id={`post_content_${a?.id}`} className='line-clamp-6'>
                            {a.url == undefined || a.url == '' ? null :
                                <div style={{paddingTop: 10, paddingBottom: 10, height: 380}} >
                                    <ImagePlaceholder src={a.url} ></ImagePlaceholder>
                                </div>
                            }
                            {a.videoUrl == undefined || a.videoUrl == '' ? null :
                                <div>
                                    <video style={{height: '380px', width: '100%'}} controls={true} >
                                        <source src={a.videoUrl} />
                                    </video>
                                </div>
                            }

                            <div dangerouslySetInnerHTML={{__html: `${a?.text}`.replace(/\n/g, '<br/>')}}></div>
                        </PostContent>

                        {isAdmin == false && a.userId != currentUser?.id ? null :
                            <PostBottomPlaceholder>
                                <DelSpan onClick={() => {
                                    setDeleteModal(a.id);
                                }}>
                                    удалить
                                </DelSpan>
                            </PostBottomPlaceholder>
                        }

                    </PostItem>
                )
            })}

            {deleteModal == undefined ? null :
                <NiceConfirm
                    heading='Удаление поста'
                    subHeading='Вы действительно хотите удалить пост?'
                    cancelText='ОТМЕНА'
                    confirmText='УДАЛИТЬ'
                    onCancel={() => setDeleteModal(undefined)}
                    onConfirm={async () => {
                        if (loading == true) {
                            return;
                        }
                        await dispatch(postsActions.deletePost(deleteModal));
                        setDeleteModal(undefined);
                    }}
                />
            }

            {/* {isAdmin == false ? null : */}
                <BottomPlaceholder>

                    
                </BottomPlaceholder>
            {/* } */}

        </Wrapper>
    );
}

const ImagePlaceholder = styled.div`
  background-image: url(${props => props.src});
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
`;

const XName = styled.div`
  font-weight: bold;
`;

const DelSpan = styled.span`
  cursor: pointer;
  color: red;

  :hover {
    opacity: 0.7;
  }
`;

const BottomPlaceholder = styled.div`

`;

const PostBottomPlaceholder = styled.div`
  text-align: right;
`;

const PostName = styled.div`
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const PostNameRightPart = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const PostType = styled.span`
    border-radius: 5px;
    padding: 3px 7px;
    background-color: ${props => props?.type?.backgroundColor};
    color: ${props => props?.type?.color};
`;

const PostContent = styled.div`
`;

const PostItem = styled.div`
  border-radius: 10px;
  padding: 15px;
  background: white;
  margin-bottom: 20px;
`;

const Search = styled.div`
    margin-bottom: 20px;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    align-items: center;
`;

const NoPosts = styled.div`
    display: flex;
    justify-content: center;
`;

const Wrapper = styled.div`
  padding: 20px;
  width: min(800px, 100vw - 350px);
  margin: 0 auto;
`;