import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import ExerciseCardItemView from "../tools/ExerciseCardItemView";
import ExerciseAnswerInputTool from "../tools/ExerciseAnswerInputTool";
import MaterialSearchPanel from "../../materials/panels/MaterialSearchPanel";
import SimpleFileUploader from "../../uploader/tools/SimpleFileUploader";
import {Textarea} from "../../ira/ui/Inputs";
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css'; // ES6

// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';

import Select from 'react-select'
import SimpleOptionsEditor from "../../tests/choice/tools/SimpleOptionsEditor";
import MatchEditorTool from "../../match/tools/MatchEditorTool";
import {GreenButton} from "../../ira/ui/Buttons";
import NiceModal from "../../modals/NiceModal";
import PhotoEditorPhotoPicker from "../../photos/tools/PhotoEditorPhotoPicker";
import MosesEditorTool from "../../moses/MosesEditorTool";
import cit_img from "../../../assets/img/citation.svg";
import SimpleCitationTool from "../../citation/SimpleCitationTool";
import HighlightEditorTool from "../../highlighting/tools/HighlightEditorTool";
import SearchOrCreateMaterialsPanel from "../../materials/panels/SearchOrCreateMaterialsPanel";
import EmphasisEditor from "../../emphasis/tools/EmphasisEditor";
import AccountingForm from "../../accounting/tools/AccountingForm";
import { translate } from '../../../utils/Translate';

export const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons

    [{'list': 'ordered'}, {'list': 'bullet'}],

    [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
    [{'header': [1, 2, 3, 4, 5, 6, false]}],

    [{'color': []}, {'background': []}],          // dropdown with defaults from theme
    [{'align': []}]
];

const TYPES_OPTIONS = [
    {
        label: 'Микрофон',
        value: 'speaking'
    },
    {
        label: 'Клавиатура',
        value: 'typing'
    },
    {
        label: 'Выбор',
        value: 'select'
    },
    {
        label: 'Сопоставление',
        value: 'match'
    },
    {
        label: 'Упорядочивание',
        value: 'order'
    },
    {
        label: 'Распознавание',
        value: 'recognition'
    },
    {
        label: 'Хайлайт',
        value: 'highlight'
    },
    {
        label: 'Без ответа',
        value: 'none'
    },
    {
        label: 'Пропуски (Drag&Drop)',
        value: 'dnd_gaps'
    },
    {
        label: 'Пропуски (ввод текста)',
        value: 'input_gaps'
    },
    {
        label: 'Группировка',
        value: 'dnd_grouping'
    },
    {
        label: 'Ударения',
        value: 'emphasis'
    },
    {
        label: 'Эссе',
        value: 'essay'
    },
    {
        label: 'Запись видео',
        value: 'video_recording'
    }
]

function isHebrew(text) {
    if (text === undefined || text.trim() === '') {
        return false;
    }
    let hebrewChars = /[\u0590-\u05FF]+/g;
    let s = text.replace(hebrewChars, '')
    return s.length < text.length
}

export default function ExerciseCardForm(props) {
    const {
        onChange = d => {

        },
        loading = false,
        onDelete = () => {

        },
        canDuplicate = true,
        onDuplicate = () => {

        }

    } = props;

    const [citationModalVisible, setCitationModalVisible] = useState(false);

    const [updT, setUpdT] = useState(0);
    const [accountingWords, setAccountingWords] = useState(props.accountingWords || []);
    const [accountingGrammarItems, setAccountingGrammarItems] = useState(props.accountingGrammarItems || []);

    const [correctAnswer, setCorrectAnswer] = useState(props.correctAnswer || '');
    const [transcript, setTranscript] = useState(props.transcript);
    const [comment, setComment] = useState(props.comment || '');
    const [copyrightDescription, setCopyrightDescription] = useState(props.copyrightDescription || '');
    const [hint, setHint] = useState(props.hint || '');
    const [imageUrl, setImageUrl] = useState(props.imageUrl);
    const [videoUrl, setVideoUrl] = useState(props.videoUrl);
    const [videoAvatar, setVideoAvatar] = useState(props.videoAvatar);
    const [audioUrl, setAudioUrl] = useState(props.audioUrl);
    const [text, setText] = useState(props.text);
    const [html, setHtml] = useState(props.html || '');
    const [answerType, setAnswerType] = useState(props.answerType);

    const [mosesDurations, setMosesDurations] = useState(props.mosesDurations || []);
    const [selectOptions, setSelectOptions] = useState(props.selectOptions || []);
    const [matchOptions, setMatchOptions] = useState(props.matchOptions || []);

    const [addVideoVisible, setAddVideoVisible] = useState(false);

    const [photoEditorVisible, setPhotoEditorVisible] = useState(false);
    const [mosesEditorVisible, setMosesEditorVisible] = useState(false);

    console.log('ExerciseCardForm: render: props = ', props);

    return (
        <Wrapper>

            <LeftPlaceholder>

                <CardPlaceholder key={updT}>
                    <ExerciseCardItemView
                        hint={hint}
                        comment={comment}
                        videoAvatar={videoAvatar}
                        imageUrl={imageUrl}
                        videoUrl={videoUrl}
                        audioUrl={audioUrl}
                        text={text}
                        html={html}
                        mosesDurations={mosesDurations}
                        answerType={answerType}
                    />

                    <ExerciseAnswerInputTool
                        text={text}
                        answerType={answerType}
                        isPreview={true}
                        selectOptions={selectOptions}
                        matchOptions={matchOptions}
                    />

                </CardPlaceholder>

                <AccountingForm
                    grammaraItems={accountingGrammarItems}
                    words={accountingWords}
                    onChange={(words_, grammarItems_) => {
                        setAccountingWords(words_);
                        setAccountingGrammarItems(grammarItems_);
                    }}
                />

                <DeletePlaceholder>
                    <DeleteCardSpan onClick={() => {
                        if (window.confirm(translate('are_you_sure')) == false) {
                            return;
                        }
                        onDelete();
                    }}>
                        {translate('delete')}
                    </DeleteCardSpan>
                    {canDuplicate == false ? null :
                        <DupSpan style={{cursor: 'pointer'}} onClick={() => {
                            onDuplicate();
                        }}>
                            {translate('duplicate')}
                        </DupSpan>
                    }
                </DeletePlaceholder>

            </LeftPlaceholder>

            <RightPlaceholder>
                <Row>
                    <Label>
                        {translate('video')}
                    </Label>
                    <ValRow>
                        <SelectVideoSpan onClick={() => {
                            setAddVideoVisible(true);
                        }}>
                            {translate('select_video')}
                        </SelectVideoSpan>


                        {videoUrl == undefined || videoUrl == '' ? null :
                            <>
                                <PhotoEditorSpan onClick={() => {
                                    setMosesEditorVisible(true);
                                }}>
                                    {translate('fragmentation')}
                                </PhotoEditorSpan>

                                <DeleteSpan onClick={() => {
                                    setVideoUrl('');
                                    onChange({
                                        videoUrl: ''
                                    });
                                }}>
                                    {translate('delete')}
                                </DeleteSpan>
                            </>
                        }
                    </ValRow>

                </Row>

                <Row>
                    <Label>
                        {translate('picture')}
                    </Label>
                    <ValRow>
                        <SimpleFileUploader
                            onSave={s => {
                                setImageUrl(s);
                                onChange({
                                    imageUrl: s
                                });
                            }}
                        />

                        <PhotoEditorSpan onClick={() => {
                            setPhotoEditorVisible(true);
                        }}>
                            {translate('editor')}
                        </PhotoEditorSpan>

                        {imageUrl == undefined || imageUrl == '' ? null :
                            <DeleteSpan onClick={() => {
                                setImageUrl('');
                                onChange({
                                    imageUrl: ''
                                });
                            }}>
                                {translate('delete')}
                            </DeleteSpan>
                        }
                    </ValRow>

                </Row>

                <Row>
                    <Label>
                        {translate('audio')}
                    </Label>
                    <ValRow>
                        <SimpleFileUploader
                            onSave={s => {
                                setAudioUrl(s);
                                onChange({
                                    audioUrl: s
                                });
                            }}
                        />
                        {audioUrl == undefined || audioUrl == '' ? null :
                            <DeleteSpan onClick={() => {
                                setAudioUrl('');
                                onChange({
                                    audioUrl: ''
                                });
                            }}>
                                {translate('delete')}
                            </DeleteSpan>
                        }
                    </ValRow>

                </Row>

                <Row>
                    <Label>
                        {translate('text')}
                    </Label>
                    <Textarea value={text}
                              style={{
                                  lineHeight: '20px',
                                  minHeight: '110px',
                                  textAlign: !isHebrew(text) ? 'left' : 'right'
                              }}
                              dir={!isHebrew(text) ? 'ltr' : 'rtl'}
                              onChange={evt => {
                                  setText(evt.target.value.replace(/’/g, "'"));
                                  console.log('text: ', evt.target.value);
                              }}/>
                </Row>

                {/*<Row>*/}
                {/*    <Label>*/}
                {/*        Форматированный текст*/}
                {/*    </Label>*/}
                {/*    <ReactQuill value={html}*/}
                {/*                modules={{toolbar: toolbarOptions}}*/}
                {/*                onChange={s => {*/}
                {/*                    setHtml(s);*/}
                {/*                }}/>*/}
                {/*</Row>*/}

                <Row>
                    <Label>
                        {translate('answer_type')}
                    </Label>
                    <Select
                        placeholder={translate('select_answer_type')}
                        value={TYPES_OPTIONS.filter(x => (x.value == answerType))[0]}
                        options={TYPES_OPTIONS}
                        onChange={x => {
                            setAnswerType(x.value);
                            onChange({
                                answerType: x.value
                            })
                        }}
                    />
                </Row>

                {answerType != 'highlight' ? null :
                    <Row>
                        <Label>
                            {translate('highlight_correct_words')}
                        </Label>
                        <HighlightEditorTool
                            text={text}
                            selWords={(correctAnswer || '').split('|').map(x => x.toLowerCase())}
                            onChange={arr => {
                                console.log('highlight: onChange: arr = ', arr);
                                let sortedArr = arr.sort((a, b) => {
                                    let s1 = a.toLowerCase();
                                    let s2 = b.toLowerCase();
                                    if (s1 > s2) {
                                        return 1;
                                    }
                                    if (s1 < s2) {
                                        return -1;
                                    }
                                    return 0;
                                });
                                setCorrectAnswer(sortedArr.join('|'));
                            }}
                        />
                    </Row>
                }

                {answerType != 'select' ? null :
                    <div style={{marginBottom: 20}}>
                        <SimpleOptionsEditor options={selectOptions} onSave={opts => {
                            setSelectOptions(opts);
                        }}/>
                    </div>
                }

                {answerType != 'emphasis' ? null :
                    <div style={{marginBottom: 20}}>
                        <EmphasisEditor positions={(correctAnswer || '').split('|').map(x => (+x))}
                                        text={text}
                                        onChange={newPositions => {
                                            setCorrectAnswer(newPositions.join('|'));
                                        }}/>
                    </div>
                }


                {(answerType != 'order' && answerType != 'match') ? null :
                    <div className={'answer_type_match_placeholder'}>
                        <MatchEditorTool mode={answerType} items={matchOptions} onChange={arr => {
                            setMatchOptions(arr);
                            setUpdT(+new Date());
                        }}/>
                    </div>
                }

                {(answerType != 'typing' && answerType != 'recognition') ? null :
                    <Row className={'field'}>
                        <Label>
                            {translate('correct_answer')}
                        </Label>
                        <Textarea value={correctAnswer}
                                  style={{
                                      lineHeight: '20px',
                                      minHeight: '60px',
                                      textAlign: isHebrew(correctAnswer) ? 'right' : 'left'
                                  }}
                                  dir={isHebrew(correctAnswer) ? 'rtl' : 'ltr'}
                                  placeholder={''}
                                  onChange={(evt) => {
                                      setCorrectAnswer(evt.target.value);
                                  }}/>
                    </Row>
                }

                <Row className={'field'}>
                    <Label>
                        {translate('comment')}
                    </Label>
                    <Textarea value={comment}
                              style={{
                                  lineHeight: '20px',
                                  minHeight: '60px',
                                  textAlign: isHebrew(comment) ? 'right' : 'left'
                              }}
                              dir={isHebrew(comment) ? 'rtl' : 'ltr'}
                              placeholder={''}
                              onChange={(evt) => {
                                  setComment(evt.target.value);
                              }}/>
                </Row>

                <Row className={'field'}>
                    <Label>
                        {translate('hint')}
                    </Label>
                    <Textarea
                        style={{lineHeight: '20px', minHeight: '60px', textAlign: isHebrew(hint) ? 'right' : 'left'}}
                        dir={isHebrew(hint) ? 'rtl' : 'ltr'}
                        value={hint}
                        placeholder={''}
                        onChange={(evt) => {
                            setHint(evt.target.value);
                        }}/>
                </Row>

                <Row className={'field'}>
                    <Label>
                        <span>
                            {translate('source_information')}
                        </span>
                        <CitImg src={cit_img} onClick={() => {
                            setCitationModalVisible(true);
                        }}/>
                    </Label>
                    <Textarea
                        style={{lineHeight: '20px', minHeight: '60px'}}
                        value={copyrightDescription}
                        placeholder={''}
                        onChange={(evt) => {
                            setCopyrightDescription(evt.target.value);
                        }}/>
                </Row>

                <SaveButtonPlaceholder>
                    <GreenButton onClick={() => {
                        // if (accountingWords.length == 0){
                        //     return window.alert('Вы забыли про целевые слова');
                        // }
                        //
                        // if (accountingGrammarItems.length == 0){
                        //     return window.alert('Вы забыли про целевые грамматические конструкции');
                        // }

                        onChange({
                            matchOptions: matchOptions,
                            selectOptions: selectOptions,
                            text: text,
                            html: html,
                            comment: comment,
                            hint: hint,
                            transcript: transcript,
                            correctAnswer: correctAnswer,
                            mosesDurations: mosesDurations,
                            copyrightDescription: copyrightDescription,
                            accountingWords: accountingWords,
                            accountingGrammarItems: accountingGrammarItems
                        })
                    }}>
                        <Spin visible={loading}/>
                        {loading == true ? null :
                            translate('save')
                        }

                    </GreenButton>
                </SaveButtonPlaceholder>


            </RightPlaceholder>

            <Sidebar
                width={Math.min(1280, window.innerWidth)}
                visible={addVideoVisible} onCloserClick={() => {
                setAddVideoVisible(false);
            }}>

                {addVideoVisible == false ? null :
                    <ModalSearchInnerPlaceholder>
                        <SearchOrCreateMaterialsPanel
                            type={'materials'}
                            onSelect={v => {
                                console.log('onSelect: v = ', v);
                                setVideoUrl(v.url);
                                setTranscript(v.transcript);
                                setCopyrightDescription(v.copyrightDescription);
                                setText(v.transcript == undefined ? text : v.transcript);
                                setAddVideoVisible(false);
                                setVideoAvatar(v.avatar);
                                onChange({
                                    videoUrl: v.url,
                                    transcript: v.transcript,
                                    text: v.transcript == undefined ? text : v.transcript,
                                    videoAvatar: v.avatar,
                                    copyrightDescription: v.copyrightDescription == undefined ? '' : v.copyrightDescription
                                });
                            }}
                        />
                    </ModalSearchInnerPlaceholder>
                }

            </Sidebar>

            {photoEditorVisible == false ? null :
                <NiceModal onClose={() => {
                    setPhotoEditorVisible(false);
                }}>

                    <div
                        style={{
                            width: 'calc(100vw - 160px)',
                            height: 'calc(100vh - 160px)'
                        }}
                    >
                        <PhotoEditorPhotoPicker
                            url={imageUrl == '' ? undefined : imageUrl}
                            loading={loading}
                            onSave={newUrl => {
                                setImageUrl(newUrl);
                                onChange({
                                    imageUrl: newUrl
                                });
                                setPhotoEditorVisible(false);
                            }}
                        />
                    </div>

                </NiceModal>
            }

            {mosesEditorVisible == false || videoUrl == undefined || videoUrl == '' ? null :
                <NiceModal onClose={() => {
                    setMosesEditorVisible(false);
                }}>

                    <ModesInner
                        style={{
                            maxHeight: 'calc(100vh - 60px)',
                            overflowY: 'auto'
                        }}
                    >
                        <MosesEditorTool
                            url={videoUrl}
                            intervals={mosesDurations}
                            onChange={arr => {
                                setMosesDurations(arr);
                            }}
                        />
                    </ModesInner>


                </NiceModal>
            }

            <Sidebar width={window.innerWidth} visible={citationModalVisible} onCloserClick={() => {
                setCitationModalVisible(false);
            }}>

                {citationModalVisible == false ? null :
                    <InnerPlaceholder>
                        <SimpleCitationTool
                            onQuote={s => {
                                setCopyrightDescription(`${copyrightDescription == '' ? '' : `${copyrightDescription}\n`}${s}`);
                                setCitationModalVisible(false);
                            }}
                        />
                    </InnerPlaceholder>
                }

            </Sidebar>


        </Wrapper>
    );
}

const InnerPlaceholder = styled.div`
  height: calc(100vh - 80px);
  width: calc(100vw - 100px);
`;


const CitImg = styled.img`
  opacity: 0.7;
  cursor: pointer;
  height: 20px;
  margin-left: 10px;

  :hover {
    opacity: 1;
  }
`;

const DeletePlaceholder = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const DupSpan = styled.div`
  cursor: pointer;
  opacity: 0.5;

  :hover {
    opacity: 1;
  }
`;

const DeleteCardSpan = styled.span`
  cursor: pointer;
  opacity: 0.5;
  color: red;

  :hover {
    opacity: 1;
  }
`;

const ModesInner = styled.div`
  width: 820px;
  box-sizing: border-box;
  @media (max-width: 840px) {
    width: 100%;
  }
`;

const PhotoEditorSpan = styled.span`
  cursor: pointer;
  cursor: pointer;
`;

const DeleteSpan = styled.span`
  cursor: pointer;
  color: red;
`;

const ValRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ModalSearchInnerPlaceholder = styled.div`
  height: calc(100vh - 140px);
`;

const SelectVideoSpan = styled.div`
  box-sizing: border-box;
  cursor: pointer;
  text-decoration: underline;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const Row = styled.div`
  margin-bottom: 5px;
`;

const Label = styled.div`
  font-weight: bold;
`;

const CardPlaceholder = styled.div`
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid lightgrey;
  border-radius: 4px;
  max-width: 750px;
`;

const LeftPlaceholder = styled.div`
  flex: 1;
`;

const RightPlaceholder = styled.div`
  box-sizing: border-box;
  width: 360px;
  padding-left: 7px;

  @media (max-width: 720px) {
    width: 100%;
  }
`;

const SaveButtonPlaceholder = styled.div`
  margin-top: 10px;
`;