import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {BlueButton, RedButton, YellowButton} from "../../ira/ui/Buttons";
import SimpleFileUploader from "../../uploader/tools/SimpleFileUploader";
import {Input} from "../../ira/ui/Inputs";
import SimpleImageUploader from "../../uploader/tools/SimpleImageUploader";
import { translate } from '../../../utils/Translate';

export default function DrochkaItemsForm(props) {
    const {
        canEdit = false,
        onSave = newArr => {

        }
    } = props;
    const [items, setItems] = useState(props.items ? props.items : []);
    const [changed, setChanged] = useState(false);

    const updateNewItems = arr => {
        setItems(arr);
        setChanged(true);
    }

    return (
        <Wrapper className={'drochka_items_form'}>

            <div className="drochka_items">

                {items.map((item, k) => {
                    let {text, imageUrl, time} = item;
                    if (time == undefined) {
                        time = 1;
                    }
                    return (
                        <Item className={'drochka_item ui form'} key={k}>
                            <Row className="text field">
                                <Label>
                                    {translate('text')}
                                </Label>
                                <Input value={text} placeholder={'Text'} disabled={!canEdit} onChange={e => {
                                    let s = e.target.value;
                                    let newItems = items.slice(0, +k)
                                        .concat([{text: s, imageUrl: imageUrl, time: time}])
                                        .concat(items.slice(+k + 1));
                                    updateNewItems(newItems);
                                }}/>
                            </Row>
                            {imageUrl == undefined ? null :
                                <div className="relative">
                                    <div className="absolute" style={{right: 10, top: 0}}>
                                        <i className={'icon trash'} onClick={() => {
                                            let newItems = items.slice(0, +k)
                                                .concat([{text: text, imageUrl: undefined, time: time}])
                                                .concat(items.slice(+k + 1));
                                            updateNewItems(newItems);
                                        }}></i>
                                    </div>
                                    <img style={{maxWidth: '100%'}} src={imageUrl}/>
                                </div>
                            }
                            <Row className="time field">
                                <Label>
                                    {translate('time')}
                                </Label>
                                <input value={time} placeholder={translate('time')} disabled={!canEdit} onChange={e => {
                                    let s = e.target.value;
                                    let newItems = items.slice(0, +k)
                                        .concat([{text: text, imageUrl: imageUrl, time: +s}])
                                        .concat(items.slice(+k + 1));
                                    updateNewItems(newItems);
                                }}/>
                            </Row>
                            
                            {canEdit == false ? null :
                                <Row className="upload field">
                                    <Label>
                                        {translate('picture')}
                                    </Label>
                                    <SimpleFileUploader onSave={url => {
                                        let newItems = items.slice(0, +k)
                                            .concat([{text: text, imageUrl: url, time: time}])
                                            .concat(items.slice(+k + 1));
                                        updateNewItems(newItems);
                                    }}/>
                                </Row>
                            }

                            {canEdit == false ? null :
                                <BottomRow>
                                    <RedButton className="pointer" onClick={() => {
                                        let newItems = items.slice(0, +k).concat(items.slice(+k + 1));
                                        updateNewItems(newItems);
                                    }}>
                                        <i className={'icon remove'}></i> {translate('delete')}
                                    </RedButton>


                                    <BlueButton className="pointer" onClick={() => {
                                        let newItems = items.concat([item]);
                                        updateNewItems(newItems);
                                    }}>
                                        <i className={'icon copy'}></i> {translate('duplicate')}
                                    </BlueButton>
                                </BottomRow>
                            }

                        </Item>
                    )
                })}

            </div>

            {canEdit == false ? null :
                <BottomRow>
                    <YellowButton className={'ui basic mini button'} onClick={() => {
                        let newItems = items.concat([{text: '', time: 2, id: `t${+new Date()}`}])
                        updateNewItems(newItems);
                    }}>
                        <i className={'icon plus'}></i> {translate('add_card')}
                    </YellowButton>

                    <YellowButton className={'ui basic mini button'} onClick={() => {
                        let copiedItems = JSON.parse(JSON.stringify(items));
                        copiedItems = copiedItems.map(it => {
                            return {...it, time: items[0].time, text: ''}
                        });
                        if (window.confirm('Вы уверены, что хотите сделать дублирование ') == false) {
                            return;
                        }
                        updateNewItems(items.concat(copiedItems));
                    }}>
                        <i className={'icon copy'}></i> {translate('extended_cards_duplication')}
                    </YellowButton>

                </BottomRow>
            }

            {changed == false || canEdit == false ? null :
                <div className="mt10">
                    <BlueButton className={'ui primary button'} onClick={() => {
                        setChanged(false);
                        onSave(items);
                    }}>
                        <i className={'icon save'}></i> {translate('save')}
                    </BlueButton>
                </div>
            }


        </Wrapper>
    );
}

const BottomRow = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Label = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;

const Wrapper = styled.div`

`;

const Row = styled.div`
  margin-bottom: 15px;
`;

const Item = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid grey;
`;
