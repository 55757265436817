import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import SmartQuizQuestionViewer from "./SmartQuizQuestionViewer";

import {ReactSortable} from "react-sortablejs";

import xImg from '../../../assets/img/x.svg'
import editImg from '../../../assets/img/write.svg'
import MultipleChoiceExerciseEditorTool from "../choice/tools/MultipleChoiceExerciseEditorTool";
import SimpleInputExerciseEditorTool from "../simple_input/tools/SimpleInputExerciseEditorTool";
import SimpleVideoRecordEditorTool from "../simple_record/SimpleVideoRecordEditorTool";
import UpdateTextItemTool from "./UpdateTextItemTool";
import PhotoEditorPhotoPicker from "../../photos/tools/PhotoEditorPhotoPicker";
import NiceModal from "../../modals/NiceModal";
import SimpleImageUploader from "../../uploader/tools/SimpleImageUploader";
import {Textarea} from '../../ira/ui/Inputs';
import cit_img from '../../../assets/img/citation.svg'
import {GreenButton} from '../../ira/ui/Buttons';
import {translate} from "../../../utils/Translate";

export default function QuestionsEditorTool(props) {
    const {
        questions = [],
        onChange = newQuestions => {

        }
    } = props;

    const [addMode, setAddMode] = useState(undefined);
    const [selectedId, setSelectedId] = useState(undefined);
    const [copyrightDescription, setCopyrightDescription] = useState(props?.item?.content?.copyrightDescription == undefined ? '' : props?.item?.content?.copyrightDescription);
    const [citationModalVisible, setCitationModalVisible] = useState(false);
    const [tempUrl, setTempUrl] = useState(undefined);

    let selectedItem = (selectedId == undefined) ? undefined : questions.filter(x => (x.id == selectedId))[0];

    return (
        <Wrapper>

            <QuestionsList>
                <ReactSortable list={questions} setList={newItems => {
                    onChange(newItems);
                }} >
                    {questions.map((q, i) => {
                        return (
                            <QuestionItem key={q.id}>
                                <DeleteImg src={xImg} onClick={() => {
                                    if (window.confirm('Вы уверены?') == false) {
                                        return;
                                    }
                                    let newQuestions = questions.filter(x => (x.id != q.id));
                                    onChange(newQuestions);
                                }}/>
                                <EditImg
                                    src={editImg}
                                    onClick={() => {
                                        setSelectedId(q.id);
                                    }}
                                />
                                <div>
                                    <SmartQuizQuestionViewer {...q} />
                                </div>
                            </QuestionItem>
                        )
                    })}
                </ReactSortable>
            </QuestionsList>

            <BottomPlaceholder>
                <AddButton onClick={() => {
                    setAddMode('image');
                }}>
                    {'+ картинка (редактор)'}
                </AddButton>
                <AddButton onClick={() => {
                    setAddMode('direct_image');
                }}>
                    {'+ картинка (загрузка)'}
                </AddButton>
                <AddButton onClick={() => {
                    setAddMode('html');
                }}>
                    {'+ текст'}
                </AddButton>

                <AddButton onClick={() => {
                    setAddMode('choice');
                }}>
                    {'+ выбор из списка'}
                </AddButton>

                <AddButton onClick={() => {
                    setAddMode('textinput');
                }}>
                    {'+ ввод текста'}
                </AddButton>

                <AddButton onClick={() => {
                    setAddMode('record');
                }}>
                    {'+ запись камеры и микрофона'}
                </AddButton>


            </BottomPlaceholder>


            {addMode != 'image' ? null :
                <NiceModal onClose={() => {
                    setAddMode(undefined);
                }}>

                    <div
                        style={{
                            width: `calc(100vw - 160px)`,
                            height: `calc(100vh - 160px)`
                        }}
                    >
                        <PhotoEditorPhotoPicker
                            url={props?.item?.content?.url}
                            copyrightDescription={props?.item?.content?.copyrightDescription}
                            hasCopyrightSection={true}
                            onSave={(newUrl, copText) => {


                                let newQuestions = questions.concat([
                                    {
                                        id: uuid(),
                                        type: 'image',
                                        url: newUrl
                                    }
                                ]);
                                onChange(newQuestions);
                                setAddMode(undefined);
                            }}
                        />
                    </div>

                </NiceModal>
            }

            <Sidebar width={920} visible={addMode != undefined && addMode != 'image'} onCloserClick={() => {
                setAddMode(undefined);
                setTempUrl(undefined);
                setCopyrightDescription(undefined);
            }}>

                {addMode != 'choice' ? null :
                    <div>
                        <MultipleChoiceExerciseEditorTool onSave={d => {
                            let newQuestions = questions.concat([
                                {
                                    id: uuid(),
                                    type: 'choice',
                                    ...d
                                }
                            ]);
                            onChange(newQuestions);
                            setAddMode(undefined);
                        }}/>
                    </div>
                }

                {addMode != 'html' ? null :
                    <div>
                        <UpdateTextItemTool onSave={d => {
                            let newQuestions = questions.concat([
                                {
                                    id: uuid(),
                                    type: 'html',
                                    html: d.text
                                }
                            ]);
                            onChange(newQuestions);
                            setAddMode(undefined);
                        }}/>
                    </div>
                }

                {addMode != 'textinput' ? null :
                    <div>
                        <SimpleInputExerciseEditorTool onSave={d => {
                            let newQuestions = questions.concat([
                                {
                                    id: uuid(),
                                    type: 'textinput',
                                    ...d
                                }
                            ]);
                            onChange(newQuestions);
                            setAddMode(undefined);
                        }}/>
                    </div>
                }

                {addMode != 'record' ? null :
                    <div>
                        <SimpleVideoRecordEditorTool onSave={d => {
                            let newQuestions = questions.concat([
                                {
                                    id: uuid(),
                                    type: 'record',
                                    ...d
                                }
                            ]);
                            onChange(newQuestions);
                            setAddMode(undefined);
                        }}/>
                    </div>
                }

                {addMode != 'direct_image' ? null :
                    <DirectImagePlaceholder>

                        {tempUrl == undefined ? null :
                            <OptImg src={tempUrl} />}

                        <SimpleImageUploader onSave={x => {
                            let newQuestions = questions.concat([
                                {
                                    id: uuid(),
                                    type: 'image',
                                    url: x,
                                    copyrightDescription: copyrightDescription
                                }
                            ]);
                            setTempUrl(x);
                            onChange(newQuestions);
                        }}/>

                        <CopyrightPlaceholder>
                            <Label>
                                <span>
                                    {translate('source_information')}
                                </span>
                                <CitImg src={cit_img} onClick={() => {
                                    setCitationModalVisible(true);
                                }}/>
                            </Label>
                            <Textarea value={copyrightDescription} placeholder={translate('source_information')} onChange={evt => {
                                setCopyrightDescription(evt.target.value);
                            }}></Textarea>
                        </CopyrightPlaceholder>

                        <GreenButton onClick={() => {
                            let newQuestions = questions;
                            newQuestions.at(-1).copyrightDescription = copyrightDescription;
                            onChange(newQuestions);
                            setCopyrightDescription(undefined);
                            setTempUrl(undefined);
                            setAddMode(undefined);
                        }}>
                            {translate('save')}
                        </GreenButton>
                    </DirectImagePlaceholder>
                }

            </Sidebar>

            {selectedItem?.type != 'image' ? null :
                <NiceModal onClose={() => {
                    setSelectedId(undefined);
                }}>

                    <div
                        style={{
                            width: `calc(100vw - 160px)`,
                            height: `calc(100vh - 160px)`
                        }}
                    >
                        <PhotoEditorPhotoPicker
                            url={selectedItem?.url}
                            copyrightDescription={selectedItem?.copyrightDescription || props?.item?.content?.copyrightDescription}
                            hasCopyrightSection={true}
                            onSave={(newUrl, copText) => {
                                let newQuestions = questions.map((xx, j) => (xx.id == selectedId ? {...xx, url: newUrl, copyrightDescription: copText} : xx));
                                onChange(newQuestions);
                                setSelectedId(undefined);
                            }}
                        />
                    </div>

                </NiceModal>
            }


            <Sidebar width={820} visible={selectedItem != undefined && selectedItem?.type != 'image'} onCloserClick={() => {
                setSelectedId(undefined);
            }}>

                {selectedItem == undefined || selectedItem.type != 'choice' ? null :
                    <div>
                        <MultipleChoiceExerciseEditorTool {...selectedItem} onSave={d => {
                            let newQuestions = questions.map((xx, j) => (xx.id == selectedId ? {...xx, ...d} : xx));
                            console.log('MultipleChoiceExerciseEditorTool: newQuestions = ', newQuestions);
                            onChange(newQuestions);
                            setSelectedId(undefined);
                        }}/>
                    </div>
                }

                {selectedItem == undefined || selectedItem.type != 'html' ? null :
                    <div>
                        <UpdateTextItemTool text={selectedItem.html} onSave={d => {
                            let newQuestions = questions.map((xx, j) => (xx.id == selectedId ? {
                                ...xx,
                                html: d.text
                            } : xx));
                            onChange(newQuestions);
                            setSelectedId(undefined);
                        }}/>
                    </div>
                }

                {selectedItem == undefined || selectedItem.type != 'textinput' ? null :
                    <div>
                        <SimpleInputExerciseEditorTool {...selectedItem} onSave={d => {
                            let newQuestions = questions.map((xx, j) => (xx.id == selectedId ? {...xx, ...d} : xx));
                            console.log('MultipleChoiceExerciseEditorTool: newQuestions = ', newQuestions);
                            onChange(newQuestions);
                            setSelectedId(undefined);
                        }}/>
                    </div>
                }

                {selectedItem == undefined || selectedItem.type != 'record' ? null :
                    <div>
                        <SimpleVideoRecordEditorTool {...selectedItem} onSave={d => {
                            let newQuestions = questions.map((xx, j) => (xx.id == selectedId ? {...xx, ...d} : xx));
                            console.log('MultipleChoiceExerciseEditorTool: newQuestions = ', newQuestions);
                            onChange(newQuestions);
                            setSelectedId(undefined);
                        }}/>
                    </div>
                }


            </Sidebar>


        </Wrapper>
    );
}

const BottomPlaceholder = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  //flex-direction: row;
  //align-items: center;
  //justify-content: center;
`;

const AddButton = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  display: flex;
  border: 1px solid grey;
  border-radius: 4px;
  height: 36px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //margin-left: 15px;
  //margin-right: 15px;
  margin: 10px;
  :hover {
    opacity: 0.75;
  }
`;

const Wrapper = styled.div`

`;

const QuestionsList = styled.div`

`;

const QuestionItem = styled.div`
  margin-bottom: 10px;
  position: relative;
  width: 100%;
  padding: 10px;
  background: white;
  box-sizing: border-box;
  border-radius: 4px;
`;

const DeleteImg = styled.img`
  width: 24px;
  height: 24px;
  top: 0px;
  right: 0px;
  position: absolute;
  cursor: pointer;
  z-index: 4;
`;

const EditImg = styled.img`
  width: 20px;
  height: 20px;
  top: 30px;
  right: 0px;
  z-index: 4;
  position: absolute;
  cursor: pointer;
`;

const Label = styled.div`
  font-weight: bold;
  margin-top: 10px;
`;

const CitImg = styled.img`
  opacity: 0.7;
  cursor: pointer;
  height: 20px;
  margin-left: 10px;

  :hover {
    opacity: 1;
  }
`;

const CopyrightPlaceholder = styled.div`
  width: 80%;
  margin: 20px;
`;

const OptImg = styled.div`
  background-color: white;
  width: 100%;
  height: 420px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${props => props.src});
`;

const DirectImagePlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;