import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {Input, Textarea} from "../../ira/ui/Inputs";
import {GreenButton} from "../../ira/ui/Buttons";
import { translate } from '../../../utils/Translate';

export default function FolderForm(props) {
    const {
        loading = false,
        onSave = d => {

        }
    } = props;
    const [name, setName] = useState(props.name || '');
    const [description, setDescription] = useState(props.description || '');

    return (
        <Wrapper>

            <Row>
                <Label>
                    {translate('title')}
                </Label>
                <Input value={name} onChange={evt => {
                    setName(evt.target.value);
                }}/>
            </Row>

            <Row>
                <Label>
                {translate('description')}
                </Label>
                <Textarea value={description} onChange={evt => {
                    setDescription(evt.target.value);
                }}/>
            </Row>

            <BottomPlaceholder>
                {loading == true ? '...' :
                    <GreenButton onClick={() => {
                        onSave({
                            name,
                            description
                        })
                    }}>
                        {translate('save')}
                    </GreenButton>
                }
            </BottomPlaceholder>

        </Wrapper>
    );
}

const BottomPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Row = styled.div`
  margin-bottom: 10px;
`;

const Label = styled.div`
  font-weight: bold;
  margin-bottom: 3px;
`;

const Wrapper = styled.div`

`;