import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import Spin from 'arui-feather/spin'
import TypingAnswerTool from "./TypingAnswerTool";
import RecordComponent from "../../record/RecordComponent";
import SelectAnswerTool from "./SelectAnswerTool";
import TextsDiff from "./TextsDiff";
import NiceModal from "../../modals/NiceModal";
import {Input} from "../../ira/ui/Inputs";
import {BlueButton} from "../../ira/ui/Buttons";
import MatchAndOrderAnswerTool from "./MatchAndOrderAnswerTool";
import TeacherAssesAndCommentTool from "../../comment/tools/TeacherAssesAndCommentTool";
import SpeechInput from "../../speech/tools/SpeechInput";
import HighlightEditorTool from "../../highlighting/tools/HighlightEditorTool";

import incorrect_cross from '../../../assets/img/incorrect_cross.svg'
import correct from '../../../assets/img/correct.svg'
import DndFillGapsExercise from "../../dnd/tools/DndFillGapsExercise";
import DndGroupingExercise from "../../dnd/tools/DndGroupingExercise";
import InputFillGapsExercise from "../../dnd/tools/InputFillGapsExercise";
import UpdateTextItemTool from "../../tests/tools/UpdateTextItemTool";
import EssayAnswerTool from "./EssayAnswerTool";
import SimpleRecordTool from "../../tests/simple_record/SimpleRecordTool";
import EmphasisEditor from "../../emphasis/tools/EmphasisEditor";
import { translate } from '../../../utils/Translate';

const TYPES = ['highlight', 'select', 'match', 'order']

export default function ExerciseAnswerInputTool(props) {
    const {
        id,
        isTeacher = false,
        isFinished = false,
        answerType = 'typing',
        loading,
        onSave = s => {

        },
        userAnswerText,
        isPreview = false,
        correctAnswer,
        showIfCorrect = false,

        text = '',

        canAnswer = true,

        autoSaveCheckboxes = false,

        teacherMark,
        teacherPassed,
        assessmentType = 'rating',

        autoMark,
        autoScore,
        teacherComment,

        onMarkUpdate = d => {

        }

    } = props;

    console.log('ExerciseAnswerInputTool: teacherMark, autoMark, teacherComment = ', teacherMark, autoMark, teacherComment);

    // console.log('ExerciseAnswerInputTool:answerType =  ', answerType);
    // console.log('ExerciseAnswerInputTool:correctAnswer =  ', correctAnswer);

    const [updateMarkModalVisible, setUpdateMarkModalVisible] = useState(false);
    const [mark, setMark] = useState(autoMark == undefined && teacherMark == undefined ? '5' : teacherMark || autoMark);
    const [comment, setComment] = useState(props.teacherComment || '');
    const [passed, setPassed] = useState(props.teacherPassed);
    const [type, setType] = useState(props.assessmentType || 'rating');
    let highlightCorrectAnswer = (correctAnswer == undefined) ? undefined : (correctAnswer.indexOf('|') == 0 ? correctAnswer.slice(1) : correctAnswer);


    // console.log('ExerciseAnswerInputTool: render: mark = ', mark);
    // console.log('ExerciseAnswerInputTool: render: type = ', type);
    // console.log('ExerciseAnswerInputTool: render: props.assessmentType = ', props.assessmentType);
    // console.log('ExerciseAnswerInputTool: render: props.isPreview = ', props.isPreview);

    let isMarkNegative = (autoMark != undefined && teacherMark == undefined) && (+autoMark < 0);

    return (
        <Wrapper>

            {/*{JSON.stringify(props)}*/}

            {answerType != 'typing' && answerType != 'recognition' ? null :
                <React.Fragment>
                    {isFinished == false ?
                        <>
                            {answerType == 'typing' ?
                                <TypingAnswerTool
                                    {...props}
                                    isPreview={isPreview}
                                    answer={userAnswerText}
                                    onSave={(s, isCorrect, correctAnswer) => {
                                        if (canAnswer == false) {
                                            return;
                                        }
                                        onSave(s, isCorrect, correctAnswer);
                                    }}
                                />
                                :
                                <>
                                    <SpeechInput
                                        {...props}
                                        isPreview={isPreview}
                                        answer={userAnswerText}
                                        onSave={(s, isCorrect, correctAnswer) => {
                                            console.log('rec tool: onSave! s = ', s);
                                            onSave(s, isCorrect, correctAnswer);
                                        }}
                                    />
                                </>
                            }
                        </>
                        :
                        <div>
                            <div style={{marginBottom: 10}}>
                                {userAnswerText}
                            </div>

                            <Label style={{marginBottom: 10}}>
                                Верный ответ:
                            </Label>

                            <div style={{marginBottom: 10}}>
                                {correctAnswer}
                            </div>

                            <Label style={{marginBottom: 10}}>
                                Различие
                            </Label>

                            <TextsDiff
                                text1={userAnswerText}
                                text2={correctAnswer}
                            />

                        </div>
                    }

                </React.Fragment>
            }

            {answerType != 'emphasis' ? null :
                <div>
                    <EmphasisEditor
                        text={text}
                        onChange={s => {
                            if (canAnswer == false) {
                                return;
                            }
                            let isCorrect = (s == correctAnswer);
                            onSave(s, isCorrect, correctAnswer);
                        }}
                    />
                </div>
            }


            {answerType != 'speaking' ? null :
                <RecordComponent
                    id={id}
                    recordedUrl={userAnswerText}
                    {...props}
                    showIfCorrect={showIfCorrect}
                    isPreview={isPreview}
                    onSave={url => {
                        console.log('onSave: url = ', url);
                        if (canAnswer == false) {
                            return;
                        }
                        onSave(url);
                    }}
                />
            }


            {answerType != 'video_recording' ? null :
                <SimpleRecordTool
                    id={id}
                    recordedUrl={userAnswerText}
                    {...props}
                    showIfCorrect={showIfCorrect}
                    isPreview={isPreview}
                    onVideoSave={url => {
                        console.log('onSave: url = ', url);
                        if (canAnswer == false) {
                            return;
                        }
                        onSave(url);
                    }}
                />
            }

            {answerType != 'highlight' ? null :
                <div>
                    <HighlightEditorTool
                        text={text}
                        selWords={(userAnswerText || '').split('|')}
                        onChange={arr => {
                            if (isFinished == true) {
                                return window.alert('Упражнение уже завершено.');
                            }
                            let sortedArr = arr.sort((a, b) => {
                                let s1 = a.toLowerCase();
                                let s2 = b.toLowerCase();
                                if (s1 > s2) {
                                    return 1;
                                }
                                if (s1 < s2) {
                                    return -1;
                                }
                                return 0;
                            });
                            let ss = sortedArr.join('|');
                            let isCorrect = (ss == highlightCorrectAnswer);
                            console.log('highlight: onChange: ss = ', ss);
                            console.log('highlight: onChange: isCorrect = ', isCorrect);
                            onSave(ss, isCorrect, highlightCorrectAnswer);
                        }}
                    />
                </div>
            }

            {answerType != 'select' ? null :
                <div>
                    {/*{JSON.stringify(props?.selectOptions)}*/}
                    <SelectAnswerTool
                        {...props}
                        autoSave={autoSaveCheckboxes}
                        canAnswer={canAnswer}
                        showIfCorrect={showIfCorrect}
                        selectOptions={props?.selectOptions == undefined ? [] : props?.selectOptions}
                        answer={userAnswerText}
                        showCorrectAnswer={showIfCorrect || isFinished}
                        onSave={(s, isCorrect) => {
                            if (isFinished == true) {
                                return window.alert('Упражнение уже завершено.');
                            }
                            console.log('SelectAnswerTool: s = ', s);
                            if (canAnswer == false) {
                                return;
                            }
                            onSave(s, isCorrect);
                        }}
                    />
                </div>
            }

            {answerType != 'dnd_gaps' ? null :
                <div key={`x_${id}`}>
                    {/*{JSON.stringify(props?.selectOptions)}*/}
                    <DndFillGapsExercise
                        {...props}
                        loading={loading}
                        canAnswer={canAnswer}
                        userAnswer={userAnswerText}
                        showCorrectAnswer={showIfCorrect || isFinished}
                        onSave={(s, isCorrect) => {
                            if (isFinished == true) {
                                // return window.alert('Упражнение уже завершено.');
                                return;
                            }
                            console.log('SelectAnswerTool: s = ', s);
                            if (canAnswer == false) {
                                return;
                            }
                            onSave(s, isCorrect);
                        }}
                    />
                </div>
            }

            {answerType != 'input_gaps' ? null :
                <div key={`x_${id}`}>
                    {/*{JSON.stringify(props?.selectOptions)}*/}
                    <InputFillGapsExercise
                        {...props}
                        loading={loading}
                        canAnswer={canAnswer}
                        userAnswer={userAnswerText}
                        showCorrectAnswer={showIfCorrect || isFinished}
                        onSave={(s, isCorrect) => {
                            if (isFinished == true) {
                                return window.alert('Упражнение уже завершено.');
                                return;
                            }
                            console.log('SelectAnswerTool: s = ', s);
                            if (canAnswer == false) {
                                return;
                            }
                            onSave(s, isCorrect);
                        }}
                    />
                </div>
            }

            {answerType != 'dnd_grouping' ? null :
                <div key={`x_${id}`}>
                    {/*{JSON.stringify(props?.selectOptions)}*/}
                    <DndGroupingExercise
                        {...props}
                        loading={loading}
                        canAnswer={canAnswer}
                        userAnswer={userAnswerText}
                        showCorrectAnswer={showIfCorrect || isFinished}
                        onSave={(s, isCorrect) => {
                            if (isFinished == true) {
                                return;
                                // return window.alert('Упражнение уже завершено.');
                            }
                            console.log('SelectAnswerTool: s = ', s);
                            if (canAnswer == false) {
                                return;
                            }
                            onSave(s, isCorrect);
                        }}
                    />
                </div>
            }


            {(answerType != 'match' && answerType != 'order') ? null :
                <div className={'answer_type_select_placeholder'}>
                    <MatchAndOrderAnswerTool
                        {...props}
                        showCorrectAnswer={showIfCorrect || isFinished}
                        canAnswer={!isFinished}
                        mode={answerType}
                        answer={userAnswerText}
                        items={props?.matchOptions}
                        onSave={(s, isCorrect) => {
                            console.log('MatchAndOrderAnswerTool: s, isCorrect = ', s, isCorrect);
                            if (canAnswer == false) {
                                return;
                            }
                            onSave(s, isCorrect);
                        }}
                    />
                </div>
            }

            {answerType != 'essay' ? null :
                isFinished === false ?
                    <TextInputPlaceholder>
                        <EssayAnswerTool
                            text={userAnswerText || ''}
                            isFinished={isFinished}
                            isPreview={isPreview}
                            isTeacher={isTeacher}
                            onSave={(answer) => {
                                if (answer == undefined || isFinished == true) {
                                    return;
                                }
                                onSave(answer.text);
                            }}/>
                    </TextInputPlaceholder>
                    :
                    <div>
                        <EssayUserAnswer dangerouslySetInnerHTML={{__html: userAnswerText}}></EssayUserAnswer>
                    </div>
            }

            {isFinished == false && isTeacher == false ? null :
                <div style={{cursor: (isTeacher) ? 'pointer' : 'default'}} onClick={() => {
                    if (isTeacher == true) {
                        setUpdateMarkModalVisible(true);
                    }
                }}>
                    <Label style={{marginBottom: 10}}>
                        {translate('answer_score')}
                    </Label>

                    {type == 'rating' ?
                        <div style={{marginBottom: 10}} onClick={() => {

                        }}>
                            {((teacherMark == undefined && autoMark == undefined) || isMarkNegative) ? translate('no_score_yet') :
                                <span>
                                    {(teacherMark || autoMark) == 0 ?
                                        <IncorrImg src={incorrect_cross}/>
                                        :
                                        TYPES.indexOf(answerType) > -1 ?
                                            <CorrectImg src={correct}/> : (teacherMark || autoMark)}
                                    {/* (teacherMark || autoMark)} */}
                                </span>}
                        </div>
                        :
                        <div style={{marginBottom: 10}} onClick={() => {

                        }}>
                            {props.teacherPassed == undefined ? translate('no_score_yet') : (props.teacherPassed ? 'Зачёт' : 'Незачёт')}
                        </div>
                    }

                </div>
            }


            {(teacherComment == undefined || teacherComment == '') ? null :
                <TeacherCommentPlaceholder>
                    {teacherComment}
                </TeacherCommentPlaceholder>
            }

            {updateMarkModalVisible == false ? null :
                <NiceModal onClose={() => {
                    setUpdateMarkModalVisible(false);
                }}>
                    <InnerModalPlaceholder>
                        <div>
                            <TeacherAssesAndCommentTool
                                hasTextComment={true}
                                rating={mark}
                                isPassed={passed}
                                type={type}
                                onRatingChange={(x, type) => {
                                    console.log('onRatingChange: x, type = ', x, type);
                                    if (type == 'rating') {
                                        setMark(x);
                                        setType('rating');
                                    } else {
                                        setPassed(x);
                                        setType('binary');
                                    }
                                }}
                                textComment={comment}
                                onTextCommentChange={s => {
                                    console.log('onTextCommentChange: s= ', s);
                                    setComment(s);
                                }}
                            />
                            {/*<Input*/}
                            {/*    value={mark}*/}
                            {/*    placeholder={'Оценка от 1 до 5'}*/}
                            {/*    onChange={evt => {*/}
                            {/*        setMark(evt.target.value);*/}
                            {/*    }}*/}
                            {/*/>*/}
                        </div>
                        <div>
                            <BlueButton onClick={() => {
                                console.log('before onMarkUpdate occured!');
                                onMarkUpdate({
                                    teacherMark: mark,
                                    teacherComment: comment,
                                    teacherPassed: passed,
                                    assessmentType: type
                                });
                                setUpdateMarkModalVisible(false);
                            }}>
                                <Spin visible={loading}/>
                                {loading == true ? null : 'Сохранить'}
                            </BlueButton>
                        </div>
                    </InnerModalPlaceholder>
                </NiceModal>
            }


        </Wrapper>
    );
}

const IncorrImg = styled.img`
  height: 24px;
  width: 24px;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
`;

const CorrectImg = styled.img`
  height: 24px;
  width: 24px;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
`;

const TeacherCommentPlaceholder = styled.div`
  font-style: italic;
  opacity: 0.5;
  font-size: 12px;
`;

const InnerModalPlaceholder = styled.div`
  width: 420px;
`;

const Wrapper = styled.div`
  padding: 5px;
`;


const Label = styled.div`
  margin-bottom: 5px;
  margin-top: 15px;
  font-weight: bold;
  font-size: 12px;
`;

const TextInputPlaceholder = styled.div`
  width: 100%;
`;

const QuestionPlaceholder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
`;

const EssayUserAnswer = styled.div`
  white-space: pre-wrap;
  word-break: break-word;
`;