import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';
import {Map} from 'immutable'

import moment from 'moment'

import * as groupsActions from '../../../redux/actions/GroupsActions';
import * as usersActions from '../../../redux/actions/UsersActions';
import * as tasksActions from '../../../redux/actions/TasksActions';
import * as lessonsActions from '../../../redux/actions/LessonsActions';
import ProgressTool from "../tools/ProgressTool";
import {BlueButton} from "../../ira/ui/Buttons";
import BrutalOverlayModal from "../../modals/BrutalOverlayModal";
import ExercisePanel from "../../exercises/panels/ExercisePanel";
import DrochkaExercisePanel from "../../drochka_exercise/panels/DrochkaExercisePanel";
import TestsListInfoTool from "../../tests/tools/TestsListInfoTool";
import GroupsAPI from "../../../api/GroupsAPI";
import TreadmillsListViewTool from "../../treadmills_exercises/panels/TreadmillsListViewTool";
import { translate } from '../../../utils/Translate';

async function loadGroupsLessons(dispatch, groups){
    console.log('loadGroupsLessons: groups = ', groups);
    for (let i in groups){
        await dispatch(lessonsActions.getGroupLessons(groups[i].id));
    }
}

export default function StudentTasksPanel(props) {
    const {
        userId,
        canSelectTask = false,
        classId = undefined
    } = props;

    console.log('StudentTasksPanel: userId = ', userId);

    const dispatch = useDispatch();

    const {
        student,
        tasks = [],
        currentUser,
        lessonsMap = Map(),
        groupsMap = Map()
    } = useMappedState(useCallback(state => {
        let uId = userId || state.users.currentUserId;
        return {
            student: state.users.usersMap.get(uId),
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            loading: state.users.loading || state.tasks.loading,
            tasks: state.tasks.tasksMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp)),
            lessonsMap: state.lessons.lessonsMap,
            groupsMap: state.groups.groupsMap,
        }
    }));

    const [selectedTaskId, setSelectedTaskId] = useState(undefined);

    const [selectedMode, setSelectedMode] = useState(undefined);
    const [selectedIds, setSelectedIds] = useState([]);

    useEffect(() => {
        dispatch(usersActions.loadUserById(userId));
        dispatch(groupsActions.loadAllGroups());
        dispatch(tasksActions.getStudentTasksWithStats(userId));
        dispatch(lessonsActions.getMyLessons());
        // GroupsAPI.getMyGroups().then(arr => {
        //     console.log('my groups = ', arr);
        //     loadGroupsLessons(dispatch, arr).then(x => {
        //         console.log('loaded!');
        //     })
        // })
    }, [userId]);

    let selectedTask = (selectedTaskId == undefined) ? undefined : tasks.filter(xx => (xx.id == selectedTaskId))[0];
    console.log('StudentTasksPanel: tasks = ', tasks);
    let currTasks = (classId == undefined) ? tasks : tasks.filter(x => (x?.classId == classId));
    console.log('currTasks = ', currTasks);

    return (
        <Wrapper>

            <TasksListPlaceholder>

                {currTasks.map((a, i) => {
                    let {
                        exercisesIds = [], passedExercisesIds = [], treadmillsIds = [],
                        drochkaExercisesIds = [], passedDrochkaExercisesIds = [],
                        testsIds = [], passedTestsIds = []
                    } = a;
                    let totalNumber = exercisesIds.length + drochkaExercisesIds.length + testsIds.length;
                    let passedNumber = passedExercisesIds.length + passedDrochkaExercisesIds.length + passedTestsIds.length;
                    let totalPercent = (totalNumber == 0) ? undefined : 1.0 * passedNumber / totalNumber;
                    let lesson = lessonsMap.get(a.lessonId);
                    let group = groupsMap.get(a.classId);
                    console.log('lesson = ', lesson);

                    return (
                        <Box key={`${a.id}_${i}`} onClick={() => {

                        }}>
                            <BoxTop onClick={() => {

                            }}>
                                <BoxTopName>
                                    {`${translate('lesson')} ${moment(lesson?.startTimestamp).format('DD.MM.YYYY HH:mm')}`}
                                </BoxTopName>

                                {totalPercent == undefined ? null :
                                    <div style={{marginLeft: 15}}>
                                        <ProgressTool progress={totalPercent}/>
                                    </div>
                                }

                            </BoxTop>

                            <LessonBoxRow>
                                <LessonRowLabel>
                                    {translate('group_name')}
                                </LessonRowLabel>
                                <LessonRowValue>
                                    {group == undefined ? '' : group.name}
                                </LessonRowValue>
                            </LessonBoxRow>

                            <LessonBoxRow clickable={exercisesIds.length > 0} onClick={() => {
                                if (exercisesIds.length == 0) {
                                    return;
                                }
                                setSelectedMode('exercises');
                                setSelectedIds(exercisesIds);
                            }}>
                                <LessonRowLabel>
                                    {translate('exercises')}
                                </LessonRowLabel>
                                <LessonRowValue>
                                    {passedExercisesIds.length}
                                    <span>{' / '}</span>
                                    {exercisesIds.length}
                                </LessonRowValue>
                            </LessonBoxRow>

                            <LessonBoxRow clickable={drochkaExercisesIds.length > 0} onClick={() => {
                                if (drochkaExercisesIds.length == 0) {
                                    return;
                                }
                                setSelectedMode('drochkaExercises');
                                setSelectedIds(drochkaExercisesIds);
                            }}>
                                <LessonRowLabel>
                                    {translate('cards')}
                                </LessonRowLabel>
                                <LessonRowValue>
                                    {passedDrochkaExercisesIds.length}
                                    <span>{' / '}</span>
                                    {drochkaExercisesIds.length}
                                </LessonRowValue>
                            </LessonBoxRow>

                            <LessonBoxRow clickable={testsIds.length > 0} onClick={() => {
                                if (testsIds.length == 0) {
                                    return;
                                }
                                setSelectedMode('tests');
                                setSelectedIds(testsIds);
                            }}>
                                <LessonRowLabel>
                                    {translate('tests')}
                                </LessonRowLabel>
                                <LessonRowValue>
                                    {passedTestsIds.length}
                                    <span>{' / '}</span>
                                    {testsIds.length}
                                </LessonRowValue>
                            </LessonBoxRow>

                            <LessonBoxRow clickable={treadmillsIds.length > 0} onClick={() => {
                                if (treadmillsIds == undefined || treadmillsIds.length == 0){
                                    return;
                                }
                                setSelectedMode('treadmills');
                                setSelectedIds(treadmillsIds);
                            }}>
                                <LessonRowLabel>
                                    {translate('treadmills')}
                                </LessonRowLabel>
                                <LessonRowValue>
                                    {treadmillsIds.length}
                                    <span>{' / '}</span>
                                    {treadmillsIds.length}
                                </LessonRowValue>
                            </LessonBoxRow>

                            {canSelectTask == false ? null :
                                <GroupActionsPlaceholder>
                                    <BlueButton style={{width: '100%'}} onClick={() => {

                                    }}>
                                        Выполнить задание
                                    </BlueButton>
                                </GroupActionsPlaceholder>
                            }

                        </Box>
                    )
                })}

            </TasksListPlaceholder>


            {selectedMode == undefined ? null :
                <BrutalOverlayModal onClose={() => {
                    setSelectedMode(undefined);
                }}>

                    <InnerModalPlaceholder>

                        {['tests', 'treadmills'].indexOf(selectedMode) === -1 ?
                            selectedIds.map((a, i) => {
                                return (
                                    <ExSelItem key={a}>
                                        {selectedMode != 'exercises' ? null :
                                            <div>
                                                <ExercisePanel
                                                    id={a}
                                                    userId={userId}
                                                />
                                            </div>
                                        }

                                        {selectedMode != 'drochkaExercises' ? null :
                                            <div>
                                                <DrochkaExercisePanel
                                                    id={a}
                                                    userId={userId}
                                                />
                                            </div>
                                        }
                                    </ExSelItem>
                                )
                            })
                            :
                            <div>
                                {selectedMode != 'tests' ? null :
                                    <div>
                                        <TestsListInfoTool
                                            ids={selectedIds}
                                        />
                                    </div>
                                }

                                {selectedMode != 'treadmills' ? null :
                                    <div>
                                        <TreadmillsListViewTool
                                            selectedTaskId={selectedTaskId}
                                            ids={selectedIds}
                                            userId={userId}
                                        />
                                    </div>
                                }
                            </div>
                        }

                    </InnerModalPlaceholder>

                </BrutalOverlayModal>
            }

        </Wrapper>
    );
}

const ExSelItem = styled.div`
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid lightgrey;
`;

const InnerModalPlaceholder = styled.div`
  background: white;
  box-sizing: border-box;
  padding: 10px;
  width: 720px;
  margin: 0 auto;
  @media(max-width: 720px){
    width: 100%;
  }
`;

const BoxTopName = styled.div`
  font-weight: bold;
  font-size: 19px;
  line-height: 23px;
  color: #1F2041;
  cursor: pointer;
`;

const GroupActionsPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;


const LessonRowLabel = styled.div`
  color: rgba(31, 32, 65, 0.5);
  font-size: 14px;
  line-height: 17px;
`;

const LessonRowValue = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: rgba(31, 32, 65, 0.75);
`;


const LessonBoxRow = styled.div`
  border-bottom: 1px solid rgba(31, 32, 65, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 15px;
  margin-bottom: 15px;
  justify-content: space-between;
  cursor: ${props => (props.clickable == true ? 'pointer' : 'default')};
`;

const Box = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 5px 10px rgba(31, 32, 65, 0.05);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 30px;
  margin-bottom: 30px;
  margin-top: 0px;
  margin: 10px;
`;

const BoxTop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 18px;
  justify-content: space-between;
  cursor: pointer;

  :hover {
    opacity: 0.8;
    text-decoration: underline;
  }
`;

const Wrapper = styled.div`

`;

const TasksListPlaceholder = styled.div`
  width: min(100vw - 300px - 60px, 1200px);
  box-sizing: border-box;
  padding: 10px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 1200px) {
    grid-template-columns:1fr 1fr;
  }
  @media (max-width: 720px) {
    grid-template-columns: 1fr;
  }
  
  @media(max-width: 720px){
    width: 100%;
    height: 100%;
  }
  
`;