import React, { useCallback, useState } from 'react'
import styled from 'styled-components';
import { BlueButton, RedButton } from '../../ira/ui/Buttons';
import { useMappedState } from 'redux-react-hook';
import { useDispatch } from 'redux-react-hook'
import { Input, Textarea } from '../../ira/ui/Inputs';
import SimpleImageUploader from '../../uploader/tools/SimpleImageUploader';
import Sidebar from 'arui-feather/sidebar';
import SearchOrCreateMaterialsPanel from '../../materials/panels/SearchOrCreateMaterialsPanel';
import * as postsActions from "../../../redux/actions/PostsActions";
import { translate } from '../../../utils/Translate';
import PostTypeTool from './PostTypeTool';
import moment from 'moment';
import edit from '../../../assets/img/edit_icon.svg';
import NiceModal from '../../modals/NiceModal';
import DateTimePicker from '../../ui/DateTimePicker';
import toast from 'react-hot-toast';

export default function CreatePostTool(props) {
    const {
        onClose = () => {

        }
    } = props;

    const {
        groups = [],
        currentUser,
        isAdmin,
        loading
    } = useMappedState(useCallback(state => {
        let {currentUserId} = state.users;
        let currentUser = state.users.usersMap.get(currentUserId);

        let isAdmin = (currentUser?.isModerator == true);

        let posts = state.posts.postsMap.toArray();
        posts = posts.sort((a, b) => (+b.timestamp - +a.timestamp));

        return {
            groups: state.groups.groupsMap.toArray().sort((a, b) => {
                return (+b.timestamp - +a.timestamp)
            }),
            currentUser: currentUser,
            loading: state.posts.loading,
            isAdmin: isAdmin
        }
    }, []));

    const dispatch = useDispatch();

    const [videoModalVisible, setVideoModalVisible] = useState(false);
    const [dateModalVisible, setDateModalVisible] = useState(false);

    const [newText, setNewText] = useState('');
    const [newName, setNewName] = useState('');
    const [url, setUrl] = useState();
    const [videoUrl, setVideoUrl] = useState();
    const [type, setType] = useState(isAdmin ? '' : 'group')
    const [time, setTime] = useState(Date.now());
    const [postGroups, setPostGroups] = useState([]);

    return (
        <Wrapper>
            <Row>
                <Label>
                    {translate('post_title')}
                </Label>
                <Input
                    value={newName}
                    onChange={evt => {
                        setNewName(evt.target.value);
                    }}
                />
            </Row>

            <Row>
                <Label>
                    {translate('post_content')}
                </Label>
                <Textarea
                    value={newText}
                    onChange={evt => {
                        setNewText(evt.target.value);
                    }}
                />
            </Row>

            <Row>
                <Label>
                    {translate('picture')}
                </Label>

                {url == undefined ? null :
                    <div>
                        <div>
                            <img src={url} style={{height: 120}}/>
                        </div>
                        <div>
                            <span style={{cursor: 'pointer', color: 'red', fontStyle: 'italic'}}
                                    onClick={() => {
                                        setUrl(undefined);
                                    }}>
                                {translate('delete')}
                            </span>
                        </div>
                    </div>
                }

                <SimpleImageUploader
                    onSave={x => {
                        setUrl(x);
                    }}
                />
            </Row>

            <Row>
                <Label>
                    {translate('video')}
                </Label>

                {videoUrl == undefined ? null :
                    <div>
                        <div>
                            <video style={{height: '380px', width: '100%'}} controls={true} >
                                <source src={videoUrl} />
                            </video>
                        </div>
                        <div>
                            <span style={{cursor: 'pointer', color: 'red', fontStyle: 'italic'}}
                                    onClick={() => {
                                        setVideoUrl(undefined);
                                    }}>
                                {translate('delete')}
                            </span>
                        </div>
                    </div>
                }

                <div style={{cursor: 'pointer'}} onClick={() => {
                    setVideoModalVisible(true);
                }} >
                    {translate('select_video')}
                </div>

            </Row>

            <Row>
                <Label>{translate('publication_date')}</Label>
                <DatePlaceholder onClick={() => setDateModalVisible(true)}>
                    {`${translate('date')}: ${moment(time).format('DD.MM.YYYY')}`} {' '}
                    {`${translate('time')}: ${moment(time).format('HH:mm')}`}
                    <Icon src={edit} />
                </DatePlaceholder>
            </Row>

            <Row>
                <Label>{translate('select_post_type')}</Label>
                <PostTypeTool
                    groups={groups}
                    currentUser={currentUser}
                    isAdmin={isAdmin}
                    type={type}
                    setType={t => setType(t)}
                    setGroups={d => setPostGroups(d)}
                />
            </Row>


            <ButtonsPlaceholder>
                <BlueButton onClick={async () => {
                    if (loading == true) {
                        return;
                    }
                    let data = {
                        text: newText,
                        name: newName,
                        url: url,
                        videoUrl: videoUrl,
                        type,
                        time,
                        postGroups,
                    }
                    if (newName == '') {
                        toast.error(translate('enter_post_title'));
                        return;
                    }
                    if (type == 'group' && postGroups.length == 0) {
                        toast.error(translate('select_groups'));
                        return;
                    }
                    if (type == undefined) {
                        toast.error(translate('select_post_type'));
                        return;
                    }

                    await dispatch(postsActions.createPost(data));
                    setNewText('');
                    setNewName('');
                    setUrl(undefined);
                    setVideoUrl(undefined);
                    setTime(Date.now());
                    setType(isAdmin ? '' : 'group');
                    setPostGroups([]);
                    onClose();
                }}>
                    {loading == true ? translate('creating') + '...' : translate('create_post')}
                </BlueButton>
                <RedButton onClick={onClose}>{translate('cancel')}</RedButton>
            </ButtonsPlaceholder>

            
            <Sidebar visible={videoModalVisible} onCloserClick={() => {
                setVideoModalVisible(false);
            }} >
                {videoModalVisible == false ? null :
                    <div style={{height: 'calc(90vh - 200px)', maxHeight: 'calc(90vh - 200px)', overflowY: 'hidden'}} >
                        <SearchOrCreateMaterialsPanel
                            onSelect={x => {
                                setVideoUrl(x?.url);
                                setVideoModalVisible(false);
                            }}
                        />
                    </div>
                }

            </Sidebar>

            {dateModalVisible == false ? null :
                <NiceModal onClose={() => setDateModalVisible(false)}>
                    <ModalInner>
                        <DateTimePicker
                            timestamp={time}
                            onChange={(t) => {
                                setTime(t);
                            }}
                        />
                    </ModalInner>
                </NiceModal>
            }

        </Wrapper>
    )
}

const Wrapper = styled.div`
    min-width: min(calc(100vw - 20px), 600px);
    display: flex;
    flex-direction: column;
`;

const Row = styled.div`
  margin-bottom: 5px;
`;

const Label = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;

const ButtonsPlaceholder = styled.div`
    display: flex;
    justify-content: space-around;
`;

const DatePlaceholder = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
`;

const Icon = styled.img`
    width: 24px;
    height: 24px;
`;

const ModalInner = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 350px;
`;