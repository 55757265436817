import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
    useReducer,
    useMemo,
    forwardRef,
    useImperativeHandle
} from 'react';
import styled from 'styled-components';
import Spin from 'arui-feather/spin'

// import {ReactMic} from 'react-mic';
import {ReactMic} from '../aliens/rect-mic';

import {BlueButton, GreenButton, RedButton} from "../ira/ui/Buttons";
import RecorderAPI from "../../api/RecorderAPI";
import ReactHelper from "../../helpers/ReactHelper";
import CommonHelper from "../../helpers/CommonHelper";
import VerfactoTracker from "../../api/TrackerAPI";
import {isMobile} from "polotno/utils/screen";
import { translate } from '../../utils/Translate';

const RecordComponent = forwardRef((props, ref) => {
    const {
        id,
        autoRecord = false,
        autoSave = false,
        recordedUrl,
        canSave = true,
        onRecorded = blob => {

        },
        onSave = url => {

        },
        onStartRecording = () => {

        },
        showStopButton = true
    } = props;

    useImperativeHandle(ref, () => ({
        stopRecording: () => {
            setRecording(false);
        }
    }));

    const [recording, setRecording] = useState(false);
    const [saving, setSaving] = useState(false);
    const [savingProgress, setSavingProgress] = useState(undefined);

    const [hasNotSaved, setHasNotSaved] = useState(false);
    const [recorderVisible, setRecorderVisible] = useState(autoRecord);
    const [updT, setUpdT] = useState(0);

    useEffect(() => {
        if (recording) {
            onStartRecording();
            let ev = new CustomEvent('recorder_start_recording', {
                detail: {
                    id: id
                }
            });
            window.dispatchEvent(ev);
        }
    }, [recording]);

    ReactHelper.useEventListener('recorder_start_recording', (a, b, c) => {
        console.log('recorder_start_recording: a.detail.id = ', a.detail.id);
        if (a?.detail?.id == id) {
            return;
        }
        setRecording(false);
        setRecorderVisible(false);
    });


    useEffect(() => {
        console.log('==>> id changed! id, recording = ', id, recording);
        if (recording == true) {
            console.log('STOPPING!');
            setRecording(false);
            // setRecordedVisible(false);
        }
    }, [id]);

    const blobRef = useRef();

    useEffect(() => {
        if (autoRecord) {
            setTimeout(() => {
                setRecording(true);
            }, 50);
        }
        return () => {
            // console.log('unmounting recorder');
            // setRecording(false);
            // setRecordedVisible(false);
        }
    }, []);

    let audioUrl = blobRef.current?.blobURL || recordedUrl;


    let audioVisible = !(audioUrl == undefined || recording == true);
    // console.log('render: audioUrl, recording = ', audioUrl, recording);
    // console.log('render: audioVisible= ', audioVisible);

    return (
        <Wrapper>

            {recorderVisible == false ? null :
                <WavePlaceholder style={{display: (recorderVisible == true) ? 'block' : 'none'}}>
                    <ReactMic
                        visualSetting={'frequencyBars'}
                        width={isMobile() ? 300 : 360}
                        height={90}
                        style={{width: window.innerWidth >= 720 ? 360 : 300, height: 90, margin: '0 auto'}}
                        strokeColor={'#FFC02C'}
                        bitRate={4000}
                        audioBitsPerSecond={32000}
                        sampleRate={4000}
                        record={recording}
                        onStop={blob => {
                            blobRef.current = blob;
                            console.log('onStop: blob = ', blob);
                            setHasNotSaved(true);
                            setRecorderVisible(false);
                            setUpdT(+new Date());
                            try {
                                onRecorded(blob.blob);
                            } catch (exc) {
                            }
                        }}
                    />
                </WavePlaceholder>
            }


            {(audioUrl == undefined || recording == true) ? null :
                <AudioPlaceholder>
                    <Audio controls={true} key={`u${updT}`}>
                        <source src={audioUrl}/>
                    </Audio>
                </AudioPlaceholder>
            }

            <BottomButtonsPlaceholder>

                {hasNotSaved == false || canSave == false ? null :
                    <span style={{marginRight: 20}}>
                        <BlueButton onClick={async () => {
                            if (saving == true) {
                                return;
                            }
                            let myBlob = blobRef.current?.blob;
                            setSaving(true);
                            let pld = await RecorderAPI.uploadUserAudio(myBlob, p => {
                                console.log('saving progress: p = ', p);
                                if (window.isNaN(p)) {
                                    return;
                                }
                                setSavingProgress(p);
                            })
                            setSaving(false);
                            setHasNotSaved(false);
                            VerfactoTracker.track('RECORDING_SAVED', {url: pld?.url});
                            onSave(pld.url);
                        }}>
                        <span style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <Spin visible={saving}/>
                            {saving == true ? ` Сохранение... ${savingProgress}%` : 'Сохранить'}
                        </span>
                    </BlueButton>
                    </span>
                }

                {recording == false ?
                    <RedButton onClick={() => {
                        if (recorderVisible == false) {
                            setRecorderVisible(true);
                            setHasNotSaved(false);
                        }
                        setTimeout(() => {
                            setRecording(true);
                        }, 100);
                    }}>
                        {(audioUrl != undefined) ? translate('record_again') : translate('start_recording')}
                    </RedButton>
                    :
                    <>
                        {showStopButton == false ? null :
                            <GreenButton onClick={() => {
                                setRecording(false);
                            }}>
                                {translate('stop_recording')}
                            </GreenButton>
                        }
                    </>

                }
            </BottomButtonsPlaceholder>

        </Wrapper>
    );
})

export default RecordComponent;

const Audio = styled.audio`
  width: 300px;
`;

const AudioPlaceholder = styled.div`
  //background: pink;
`;

const BottomButtonsPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`;

const Wrapper = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WavePlaceholder = styled.div`
  width: 360px;
  height: 90px;
  margin: 0 auto;

`;