import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import Select from "react-select";
import SimpleFileUploader from "../../uploader/tools/SimpleFileUploader";
import MaterialSearchPanel from "../../materials/panels/MaterialSearchPanel";
import {BlueButton, GreenButton} from "../../ira/ui/Buttons";
import {Input, Textarea} from "../../ira/ui/Inputs";
import cit_img from "../../../assets/img/citation.svg";
import SimpleCitationTool from "../../citation/SimpleCitationTool";
import TagsSelectTool from "../../tags/panels/TagsSelectTool";
import SearchOrCreateMaterialsPanel from "../../materials/panels/SearchOrCreateMaterialsPanel";
import AccessGroupsDropdownPanel from "../../access_groups/panels/AccessGroupsDropdownPanel";
import {ACCESS_OPTIONS} from "../../exercises/forms/ExerciseInfoForm";
import { translate } from '../../../utils/Translate';

export default function DrochkaInfoForm(props) {

    const {
        canEdit = false,
        onSave = d => {

        },
        loading = false
    } = props;

    const [access, setAccess] = useState(props.access ? props.access : 'private');
    const [accessGroupsIds, setAccessGroupsIds] = useState(props?.accessGroupsIds ? props.accessGroupsIds : []);
    const [subjectTags, setSubjectTags] = useState(props.subjectTags ? props.subjectTags : []);
    const [grammarTags, setGrammarTags] = useState(props.grammarTags ? props.grammarTags : []);
    const [name, setName] = useState(props.name ? props.name : '');
    const [description, setDescription] = useState(props.description ? props.description : '');
    const [studentTask, setStudentTask] = useState(props.studentTask ? props.studentTask : '');
    const [copyrightText, setCopyrightText] = useState(props.copyrightText ? props.copyrightText : '');
    const [avatar, setAvatar] = useState(props.avatar ? props.avatar : props.imageUrl);
    const [videoUrl, setVideoUrl] = useState(props.videoUrl);

    const [selectVideoModalVisible, setSelectVideoModalVisible] = useState(false);
    const [citationModalVisible, setCitationModalVisible] = useState(false);

    return (
        <Wrapper className={'update_drochka_form'}>

            <div className="ui form ">

                <Row className="field">
                    <Label>
                        {translate('title')}
                    </Label>
                    <Input value={name} placeholder={translate('title')} disabled={!canEdit} onChange={(evt) => {
                        setName(evt.target.value);
                    }}/>
                </Row>

                <Row className="field">
                    <Label>
                        {translate('description')}
                    </Label>
                    <Textarea value={description} placeholder={translate('description')} disabled={!canEdit} onChange={(evt) => {
                        setDescription(evt.target.value);
                    }}></Textarea>
                </Row>

                <Row className="field">
                    <Label>
                        {translate('student_task_formulation')}
                    </Label>
                    <Textarea value={studentTask} placeholder={translate('student_task_formulation')} disabled={!canEdit} onChange={(evt) => {
                        setStudentTask(evt.target.value);
                    }}></Textarea>
                </Row>


                <Row className="field">
                    <Label>
                        {translate('source_information')}
                        <CitImg src={cit_img} onClick={() => {
                            setCitationModalVisible(true);
                        }}/>
                    </Label>
                    <Textarea value={copyrightText} placeholder={translate('source_information')} disabled={!canEdit} onChange={(evt) => {
                        setCopyrightText(evt.target.value);
                    }}></Textarea>
                </Row>

                <Row className="field">
                    <Label>
                        <span>
                            {translate('access')}
                        </span>
                    </Label>
                    <Select options={ACCESS_OPTIONS}
                            isDisabled={!canEdit}
                            value={ACCESS_OPTIONS.filter(x => (x.value == access))[0]}
                            onChange={a => {
                                setAccess(a.value);
                            }}/>
                </Row>

                {access != 'shared' ? null :
                    <Row className="field">
                        <Label>
                        <span>
                            {translate('access_groups')}
                        </span>
                        </Label>
                        <AccessGroupsDropdownPanel
                            selectedIds={accessGroupsIds}
                            onChange={x => {
                                setAccessGroupsIds(x);
                            }}
                        />
                    </Row>
                }


                <Row className="field">
                    <Label>
                        {translate('grammar_tags')}
                    </Label>
                    <TagsSelectTool
                        prefix={'grammar'}
                        isDisabled={!canEdit}
                        selectedTags={grammarTags}
                        onChange={x => {
                            setGrammarTags(x);
                        }}
                    />
                </Row>

                <Row className="field">
                    <Label>
                        {translate('content_tags')}
                    </Label>
                    <TagsSelectTool
                        prefix={'subject'}
                        isDisabled={!canEdit}
                        selectedTags={subjectTags}
                        onChange={x => {
                            setSubjectTags(x);
                        }}
                    />
                </Row>



                <Row className="field">
                    <Label>
                        {translate('avatar')}
                    </Label>
                    <div>
                        {avatar == undefined ? null :
                            <img src={avatar}/>
                        }
                    </div>
                    {canEdit == false ? null :
                        <SimpleFileUploader
                            currentImage={avatar}
                            onSave={url => {
                                setAvatar(url);
                            }}
                        />
                    }
                    {avatar == '' || canEdit == false ? null :
                        <DelelteAvatarButton onClick={() => {
                            setAvatar(''); 
                         }}>{translate('delete')}</DelelteAvatarButton>
                    }
                </Row>

                <Row className="field">
                    <Label>
                        {translate('video')}
                    </Label>
                    <div>
                        {videoUrl == undefined ? null :
                            <Video key={videoUrl} controls={true}>
                                <source src={videoUrl}/>
                            </Video>
                        }
                    </div>
                    {/*<UploadVideoWrapper onVideoUploaded={(url) => {*/}
                    {/*this.setState({*/}
                    {/*videoUrl: url*/}
                    {/*});*/}
                    {/*}} >*/}
                    {/**/}
                    {/*</UploadVideoWrapper>*/}
                    {canEdit == false ? null :
                        <BlueButton onClick={() => {
                            setSelectVideoModalVisible(true);
                        }}>
                            <i className={'icon upload'}></i> {translate('add_video_example')}
                        </BlueButton>
                    }
                </Row>

                {canEdit == false ? null :
                    <div className="field">
                        <Spin visible={loading}/>
                        {loading == true ? null :
                            <GreenButton className={'ui primary button fluid'} onClick={() => {
                                onSave({
                                    name,
                                    description,
                                    studentTask,
                                    avatar,
                                    videoUrl,
                                    copyrightText,
                                    grammarTags,
                                    subjectTags,
                                    accessGroupsIds,
                                    access
                                })
                            }}>
                                <i className={'icon save'}></i> {translate('save')}
                            </GreenButton>
                        }
                    </div>
                }

            </div>


            <Sidebar visible={selectVideoModalVisible}
                     width={Math.min(window.innerWidth, 1280)}
                     onCloserClick={() => {
                         setSelectVideoModalVisible(false);
                     }}>

                {selectVideoModalVisible == false ? null :
                    <SearchOrCreateMaterialsPanel
                        type={'materials'} onSelect={m => {
                        setVideoUrl(m.url);
                        setSelectVideoModalVisible(false);
                    }}/>
                }

            </Sidebar>

            <Sidebar width={window.innerWidth} visible={citationModalVisible} onCloserClick={() => {
                setCitationModalVisible(false);
            }}>

                {citationModalVisible == false ? null :
                    <InnerPlaceholder>
                        <SimpleCitationTool
                            onQuote={s => {
                                setCopyrightText(`${copyrightText == undefined ? '' : copyrightText}\n${s}`);
                                setCitationModalVisible(false);
                            }}
                        />
                    </InnerPlaceholder>
                }

            </Sidebar>

        </Wrapper>
    );
}

const InnerPlaceholder = styled.div`
  height: calc(100vh - 80px);
  width: calc(100vw - 100px);
`;

const CitImg = styled.img`
  opacity: 0.7;
  cursor: pointer;
  height: 20px;
  margin-left: 10px;

  :hover {
    opacity: 1;
  }
`;

const Row = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;

const Wrapper = styled.div`

`;

const Video = styled.video`
  width: 540px;
  height: 320px;
  
  @media (max-width: 720px){
    width: 95vw;
    height: 180px;
  }
`;

const DelelteAvatarButton = styled.div`
  color: red;
  cursor: pointer;
`;