import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import ReactQuill from 'react-quill';

import {BlueButton} from "../../../ira/ui/Buttons";
import LottieLoader from "../../../loaders/LottieLoader";

import lottie_json from '../../../../assets/lottie/graphic-designer.json'
import cit_img from "../../../../assets/img/citation.svg";
import SimpleCitationTool from "../../../citation/SimpleCitationTool";
import { Textarea } from "../../../ira/ui/Inputs";
import { translate } from '../../../../utils/Translate';

const quillModules = {
    toolbar: [
        [{ 'header': [1, 2, false] }],
        [{ color: [] }, { background: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote', { direction: 'rtl' }, { 'align': [] }],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean'],
    ],
}

export default function SlideTextEditor(props) {
    const {
        html = '',
        onChange = (newHtml, copyrightDescription) => {

        },
        loading = false
    } = props;

    const editorRef = useRef(null);
    const [text, setText] = useState(html);
    const [copyrightDescription, setCopyrightDescription] = useState(props.copyrightDescription || '');
    const [citationModalVisible, setCitationModalVisible] = useState(false);

    return (
        <Wrapper>

            {loading == false ? null :
                <div style={{height: 400}}>
                    <LottieLoader json={lottie_json}/>
                </div>
            }


            <EditorPlaceholder style={{display: (loading ? 'none' : 'block')}}>
                <ReactQuill
                    style={{height: '100%'}}
                    value={text}
                    onChange={(evt) => {
                        setText(evt);
                    }}
                    modules={quillModules}
                />
                
            </EditorPlaceholder>

            <Row>
                <Label>
                    <span>
                        {translate('source_information')}
                    </span>
                    <CitImg src={cit_img} onClick={() => {
                        setCitationModalVisible(true);
                    }}/>
                </Label>
                <Textarea value={copyrightDescription} onChange={evt => {
                    setCopyrightDescription(evt.target.value);
                }}></Textarea>
            </Row>

            {loading ? null :
                <SaveButtonPlaceholder>
                    <BlueButton onClick={() => {
                        onChange(text, copyrightDescription);
                    }}>
                        <Spin visible={loading}/>
                        {loading == true ? null :
                            'Сохранить'
                        }
                    </BlueButton>
                </SaveButtonPlaceholder>
            }

            <Sidebar width={window.innerWidth} visible={citationModalVisible} onCloserClick={() => {
                setCitationModalVisible(false);
            }}>

                {citationModalVisible == false ? null :
                    <InnerPlaceholder>
                        <SimpleCitationTool
                            onQuote={s => {
                                setCopyrightDescription(s);
                                setCitationModalVisible(false);
                            }}
                        />
                    </InnerPlaceholder>
                }

            </Sidebar>

        </Wrapper>
    );
}

const InnerPlaceholder = styled.div`
  height: calc(100vh - 80px);
  width: calc(100vw - 100px);
`;

const CitImg = styled.img`
  opacity: 0.7;
  cursor: pointer;
  height: 20px;
  margin-left: 10px;

  :hover {
    opacity: 1;
  }
`;

const EditorPlaceholder = styled.div`
    height: 400px;

    .ql-container {
        height: calc(100% - 42px);
    }

    .ql-editor {
        font-size: 16px;
        outline: none;
        overflow-y: auto;
        resize: vertical;

        :focus {
            outline: none;
        }
    }
`;

const Row = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
`;

const Label = styled.div`
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
`;

const SaveButtonPlaceholder = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Wrapper = styled.div`
  min-height: 400px;
`;