import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
    useReducer,
    useMemo,
    forwardRef,
    useImperativeHandle
} from 'react';
import styled from 'styled-components';

import {observer} from 'mobx-react-lite';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';

import {Workspace} from 'polotno/canvas/workspace';
import {SidePanel, DEFAULT_SECTIONS, SectionTab} from 'polotno/side-panel';
import Toolbar from 'polotno/toolbar/toolbar';

import {createStore} from "polotno/model/store";
import {POLOTNO_KEY} from "../../../constants/config";
// import RecordComponent from "../../record/RecordComponent";

import {Textarea} from "../../ira/ui/Inputs";

import cop_img from '../../../assets/img/copyright.svg'
import ReactHelper from "../../../helpers/ReactHelper";
import cit_img from "../../../assets/img/citation.svg";
import SimpleCitationTool from "../../citation/SimpleCitationTool";
import Sidebar from "arui-feather/sidebar";
import { translate } from '../../../utils/Translate';

const store = createStore({
    key: POLOTNO_KEY,
    showCredit: true
});

store.setSize(1200, 628);

const page = store.addPage({});

const getImageDimensions = url =>
    new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => {
            const {naturalWidth: width, naturalHeight: height} = img
            resolve({width, height})
        }
        // and this handler will fire if there was an error with the image (like if it's not really an image or a corrupted one)
        img.onerror = () => {
            reject('There was some problem with the image.')
        }
        img.src = url;
    })

const CopyrightSection = {
    name: 'Copyright',
    Tab: (props) => (
        <SectionTab name="Copyright" {...props}>
            <CopyrightImg src={cop_img}/>
        </SectionTab>
    ),
    Panel: observer(({store}) => {
        return (
            <div>
                <Row>
                    <Label>
                        {translate('source_information')}
                        <CitImg src={cit_img} onClick={() => {
                            try {
                                let ev = new CustomEvent('image_copyright_open_wizard', { // dirty cheating
                                    detail: {}
                                });
                                window.dispatchEvent(ev);
                            } catch (exc) {

                            }
                        }}/>
                    </Label>
                    <Textarea
                        id={'image_cit_textarea'}
                        defaultValue={window.DEFAULT_IMAGE_COPYRIGHT_TEXT || ''}
                        onChange={evt => {
                            try {
                                let ev = new CustomEvent('image_copyright_change', { // dirty cheating
                                    detail: {
                                        text: evt.target.value
                                    }
                                });
                                window.DEFAULT_IMAGE_COPYRIGHT_TEXT = evt.target.value;
                                window.dispatchEvent(ev);
                            } catch (exc) {

                            }

                        }}/>
                </Row>
            </div>
        );
    }),
};


const PolotnoTool = forwardRef((props, ref) => {
    const {
        hasCopyrightSection = false,
    } = props;

    const [loading, setLoading] = useState(false);
    const [citationModalVisible, setCitationModalVisible] = useState(false);
    const [copyrightDescription, setCopyrightDescription] = useState(props.copyrightDescription || '');

    let leftSections = DEFAULT_SECTIONS;
    if (hasCopyrightSection == true) {
        leftSections = leftSections.concat([
            CopyrightSection
        ])
    }

    console.log('PolotnoTool: hasCopyrightSection = ', hasCopyrightSection);
    ReactHelper.useEventListener('image_copyright_change', (a, b, c) => {
        setCopyrightDescription(a?.detail?.text);
        window.DEFAULT_IMAGE_COPYRIGHT_TEXT = a?.detail?.text;
    });

    ReactHelper.useEventListener('image_copyright_open_wizard', (a, b, c) => {
        setCitationModalVisible(true);
    });

    useEffect(() => {
        try {
            window.DEFAULT_IMAGE_COPYRIGHT_TEXT = props.copyrightDescription;
        } catch (exc) {

        }
    }, []);

    useImperativeHandle(ref, () => ({
        getJSON: () => {
            let json = store.toJSON();
            return json;
        },
        getDataUrl: () => {
            try {
                let dataUrl = store.toDataURL({mimeType: 'image/jpg', pageId: store.pages[0].id});
                return dataUrl;
            } catch (exc) {

            }
        },

        getCopyrightDescription: () => {
            return copyrightDescription;
        },

        getCurrentDimensions: () => {
            console.log('getCurrentDimensions occured!');
        },

        cleanCanvas: () => {
            let pages = store.pages;
            console.log('pages = ', pages);
            for (let p of pages) {
                console.log('p = ', p);
            }
            store.deletePages(pages.map(x => x.id));
            const page = store.addPage();
            page.set({
                background: 'white'
            });
        },

        setImageForEditing: imUrl => {
            let pages = store.pages;
            for (let p of pages) {
                console.log('p = ', p);
            }
            store.deletePages(pages.map(x => x.id));
            const page = store.addPage();
            page.set({
                background: 'white'
            });
            getImageDimensions(imUrl).then(d => {
                store.setSize(d.width, d.height);
                page.addElement({
                    type: 'image',
                    x: 0,
                    y: 0,
                    width: d.width,
                    height: d.height,
                    rotation: 0,
                    src: imUrl,
                    clipSrc: '',
                    selectable: true,
                    alwaysOnTop: false,
                    showInExport: true,
                });
            });
        },

    }));

    return (
        <Wrapper>

            <LeftPlaceholder>
                <SidePanel store={store} sections={leftSections}/>
            </LeftPlaceholder>

            <RightPlaceholder>

                <ToolbarPlaceholder>
                    <Toolbar store={store}
                             downloadButtonEnabled={true}
                    />
                </ToolbarPlaceholder>

                <CanvasPlacehodler>
                    <Workspace store={store}/>
                </CanvasPlacehodler>

            </RightPlaceholder>

            <Sidebar width={window.innerWidth} visible={citationModalVisible} onCloserClick={() => {
                setCitationModalVisible(false);
            }}>

                {citationModalVisible == false ? null :
                    <InnerPlaceholder>
                        <SimpleCitationTool
                            onQuote={s => {
                                setCopyrightDescription(s);
                                setCitationModalVisible(false);
                                window.DEFAULT_IMAGE_COPYRIGHT_TEXT = s;
                                try {
                                    document.getElementById('image_cit_textarea').value = s;
                                } catch (ee) {

                                }
                            }}
                        />
                    </InnerPlaceholder>
                }

            </Sidebar>

        </Wrapper>
    );
});

export default PolotnoTool;

const InnerPlaceholder = styled.div`
  height: calc(100vh - 80px);
  width: calc(100vw - 100px);
`;

const CitImg = styled.img`
  opacity: 0.7;
  cursor: pointer;
  height: 20px;
  margin-left: 10px;

  :hover {
    opacity: 1;
  }
`;

const Row = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const CopyrightImg = styled.img`
  height: 24px;
`;

const ToolbarPlaceholder = styled.div`
  height: 50px;
`;

const CanvasPlacehodler = styled.div`
  height: calc(100% - 50px);
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;

  flex-direction: row;
  align-items: flex-start;

`;

const LeftPlaceholder = styled.div`
  width: 400px;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  background: lightgrey;
`;

const RightPlaceholder = styled.div`
  flex: 1;
  height: 100%;
  background: pink;
`;