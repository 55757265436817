import React, {useState, useEffect, useCallback} from 'react';
import styled from 'styled-components';

import * as actions from '../../../redux/actions/TreadmillsActions'
import * as scaleActions from '../../../redux/actions/TreadmillScaleActions'
import {Input} from "../../ira/ui/Inputs";
import NiceModal from "../../modals/NiceModal";
import Tabs from "../../ui/Tabs";
import TreadmillEditor from "../treadmill/editor/TreadmillEditor";
import SmartTreadmillPlayerTool from "../treadmill/tools/SmartTreadmillPlayerTool";
import UpdateTreadmillForm from "../treadmill/forms/UpdateTreadmillForm";

import {Button} from "../../ui/Button";
import {useMappedState, useDispatch} from "redux-react-hook";
import {translate} from "../../../utils/Translate";
import tick_img from "../../../assets/img/green_check.svg";
import {Set} from "immutable";
import {hasIntersection} from "../../exercises/panels/AllExercisesPanel";
import {GreenButton, RedButton} from "../../ira/ui/Buttons";
import CommonHelper from "../../../helpers/CommonHelper";
import share_img from "../../../assets/img/share_mini.svg";
import ava from "../../../assets/img/online-learning-using-tablet.png"
import toast from "react-hot-toast";
import {isMobile} from "polotno/utils/screen";
import mine_svg from '../../../assets/img/mine.svg'
import edit_svg from "../../../assets/img/edit_icon.svg";
import ls from 'local-storage'
import ExercisesFoldersPanel from "../../smart_folders/panels/ExecisesFoldersPanel";
import AllDrochkaExercisesPanel from "../../drochka_exercise/panels/AllDrochkaExercisesPanel";

export const createTreadmillItemsFromCard = (card) => {
    if (card == undefined || card.items == undefined || card.items.length == 0) {
        return []
    }
    let itemBase = {
        backgroundColor: '#000000',
        textColor: '#ffffff',
        textSize: 14,
        inputType: 'none',
    }
    let items = [];
    let from = 0;
    if (card.studentTask != undefined && card.studentTask.trim() != '') {
        items = items.concat({
            ...itemBase,
            textPosition: 'center',
            id: `t-${+new Date()}-${Math.round(Math.random() * 1000000)}`,
            text: card.studentTask,
            duration: 10,
            from: +from,
            to: +from + 10,
        })
        from += 10
    }

    if (card.videoUrl != undefined && card.videoUrl != '') {
        items = items.concat({
            ...itemBase,
            id: `t-${+new Date()}-${Math.round(Math.random() * 1000000)}`,
            duration: 10,
            from: +from,
            to: +from + 10,
            videoSrc: card.videoUrl,
        })
        from += 10
    }

    for (let item of card?.items) {
        items = items.concat({
            ...itemBase,
            id: `t-${+new Date()}-${Math.round(Math.random() * 1000000)}`,
            hasBackgroundColor: true,
            textBackgroundColor: '#000000',
            textPosition: 'bottom',
            imageSrc: item.imageUrl,
            text: item.text,
            duration: +item.time,
            from: +from,
            to: +from + +item.time,
        })
        from += item.time;
    }

    return items;
}

//from card to treadmill object
export const createTreadmillObjectFromCard = (card) => {
    if (card == undefined || card.items == undefined || card.items.length == 0) {
        return undefined;
    }
    let itemBase = {
        backgroundColor: '#000000',
        textColor: '#ffffff',
        textSize: 14,
        inputType: 'none',
    }
    let items = [];
    let from = 0;
    if (card.studentTask != undefined && card.studentTask.trim() != '') {
        items = items.concat({
            ...itemBase,
            textPosition: 'center',
            id: `t-${+new Date()}-${Math.round(Math.random() * 1000000)}`,
            text: card.studentTask,
            duration: 10,
            from: +from,
            to: +from + 10,
        })
        from += 10
    }

    if (card.videoUrl != undefined && card.videoUrl != '') {
        items = items.concat({
            ...itemBase,
            id: `t-${+new Date()}-${Math.round(Math.random() * 1000000)}`,
            duration: 10,
            from: +from,
            to: +from + 10,
            videoSrc: card.videoUrl,
        })
        from += 10
    }

    for (let item of card?.items) {
        items = items.concat({
            ...itemBase,
            id: `t-${+new Date()}-${Math.round(Math.random() * 1000000)}`,
            hasBackgroundColor: true,
            textBackgroundColor: '#000000',
            textPosition: 'bottom',
            imageSrc: item.imageUrl,
            text: item.text,
            duration: +item.time,
            from: +from,
            to: +from + +item.time,
        })
        from += item.time;
    }
    
    let treadmillData = {
        name: card?.name,
        avatar: card?.avatar,
        description: card?.description,
        sourcesText: card?.copyrightText,
        studentTask: card?.studentTask,
        fromCard: card?.id,
        // creatorId: currentUser.id, //lirmak user id??
        creatorId: 'lirmak id', //lirmak user id??
        items: items
    };
    return treadmillData;
}

export default function TreadmillsPanel(props) {
    const {
        mode = 'my',
        selectMode = false,
        onSelect = x => {

        },
        selectedSet = Set()
    } = props;

    const dispatch = useDispatch();

    const [addModalVisible, setAddModalVisible] = useState(false);
    const [createFromCardsModalVisible, setCreateFromCardsModalVisible] = useState(false);
    const [selectedTreadmillId, setSelectedTreadmillId] = useState(undefined);
    const [text, setText] = useState('');
    const [copyModalVisible, setCopyModalVisible] = useState(false);
    const [copyName, setCopyName] = useState('');

    const [currentTime, setCurrentTime] = useState(0);
    const [isPaused, setIsPaused] = useState(false);
    const [tabNumber, setTabNumber] = useState(undefined);
    const [currentSlideId, setCurrentSlideId] = useState(0);

    function setPause(value) {
        setIsPaused(value);
    }

    function setTime(value) {
        setCurrentTime(value);
    }

    useEffect(() => {
        dispatch(actions.loadTreadmills());
    }, []);

    useEffect(() => {
        if (currentTime >= treadmillTime) {
            setIsPaused(true);
        }
    })

    const {
        treadmills = [],
        user,
        loading = false,
        currentUserId,
        isInitialized,
        isModerator,
        scale
    } = useMappedState(useCallback(state => {
        let {currentUserId} = state.users;
        let currentUser = state.users.usersMap.get(currentUserId);
        let allTreadmills = state?.treadmills?.treadmillsMap.toArray();
        let exercises = (mode == 'my') ? allTreadmills.filter(x => (x.creatorId == currentUserId)) : allTreadmills;

        let aGroups = state.accessGroups.accessGroupsMap.toArray().filter(x => ((x?.emails || []).indexOf(currentUser.email) == -1 && x?.creatorId != currentUserId));
        let aGroupsIds = aGroups.map(xx => xx?.id).filter(x => (x != undefined));
        if (mode == 'shared') {
            exercises = exercises.filter(x => hasIntersection((x?.accessGroupsIds || []), aGroupsIds));
        }
        if (mode == 'public') {
            // exercises = exercises.filter(x => (x?.access == 'public'));
            exercises = exercises;
        }

        return {
            isInitialized: state.users.isInitialized,
            user: state.users.usersMap.get(state.users.currentUserId),
            treadmills: exercises,
            loading: state.treadmills.loading,
            currentUserId: state.users?.currentUserId,
            isModerator: currentUser != undefined && currentUser.isModerator,
            scale: state.treadmillScale.scale
        }
    }));

    useEffect(() => {
        dispatch(actions.loadTreadmills());
        dispatch(scaleActions.loadTreadmillScale(currentUserId));
        setTime(0);
        setIsPaused(false);
    }, []);

    let selectedTreadmill = (selectedTreadmillId == undefined) ? undefined : treadmills.filter(q => (q.id == selectedTreadmillId))[0];
    let treadmillTime = selectedTreadmill?.items === undefined || selectedTreadmill?.items?.length === 0 ? 0 : selectedTreadmill?.items.at(-1).to;

    let fArr = treadmills;
    if (text != '') {
        fArr = fArr.filter(x => (x.name != undefined && (x.name.toLowerCase().indexOf(text.toLowerCase()) > -1)))
    }

    console.log('TreadmillsPanel: selectMode = ', selectMode);

    return (
        <Wrapper className={''}>
            <TopPlaceholder>
                {/*{mode == 'my' ? null :*/}
                {/*    <div>*/}
                {/*        <GreenButton onClick={() => {*/}
                {/*            setAddModalVisible(true);*/}

                {/*        }}>*/}
                {/*            {translate('create_treadmill')}*/}
                {/*        </GreenButton>*/}
                {/*    </div>*/}
                {/*}*/}
                {mode == 'my' ? null :
                    <SearchPlaceholder>
                        <Input value={text} placeholder={translate('search')} onChange={evt => {
                            setText(evt.target.value);
                        }} />
                    </SearchPlaceholder>
                }
                {mode != 'my' ? null :
                    <CreateTreadmillsButtons>
                        {/* <GreenButton onClick={() => {
                            setAddModalVisible(true);

                        }}>
                            {translate('create_treadmill')}
                        </GreenButton> */}

                        
                    </CreateTreadmillsButtons>
                }

            </TopPlaceholder>

            {mode == 'my' ? null :
                <>
                    {fArr.map((q, k) => {
                        let isSelected = selectedSet.has(q.id);
                        return (
                            <Quarter key={q.id} onClick={() => {
                                setSelectedTreadmillId(q.id);
                            }}>
                                <AvatarImage src={q?.avatar || ava}/>
                                <NamePlaceholder>
                                    <Name>
                                        {q.name}
                                    </Name>
                                    {(q?.items || [])?.filter(x => x?.hasMine === true).length === 0 || q.items === undefined ? null :
                                        <MineIcon title="В тренажере присутстуют мины" src={mine_svg}/>
                                    }
                                </NamePlaceholder>
                                {selectMode == false ? null :
                                    <div style={{
                                        marginTop: 10,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}>
                                        {isSelected == false ? null :
                                            <img src={tick_img} style={{height: 18, marginRight: 5}}/>
                                        }
                                        <SelectSpan onClick={(evt) => {
                                            evt.stopPropagation();
                                            onSelect(q);
                                        }}>
                                            {translate('select')}
                                        </SelectSpan>
                                    </div>
                                }
                            </Quarter>
                        )
                    })}
                </>
            }

            {mode != 'my' ? null :
                <React.Fragment>
                    {/* <GreenButton onClick={() => {
                            setCreateFromCardsModalVisible(true);
                        }}>
                            Создать тредмил из карточки
                    </GreenButton> */}

                    <ExercisesFoldersPanel
                        exerciseSelectMode={selectMode}
                        selectedExerciseSet={selectedSet}
                        type={'treadmill'}
                        onAddExerciseClick={() => {
                            setAddModalVisible(true);
                        }}
                        onEditExerciseClick={(id) => {
                            setSelectedTreadmillId(id);
                        }}
                        onCreateTreadmillFromCardClick={() => {
                            setCreateFromCardsModalVisible(true);
                        }}
                        onExerciseSelect={onSelect}
                    />
                </React.Fragment>
            }


            {selectedTreadmill == undefined ? null :
                <NiceModal className={'zIndex2'} width={Math.min(1200, window.innerWidth)}
                           onClose={() => {
                               setSelectedTreadmillId(undefined);
                           }}>

                    {selectedTreadmill == undefined ? null :
                        <FullWindow>
                            <Tabs
                                changeTab={tabNumber}
                                tabUpdated={() => setTabNumber(undefined)}
                                tabs={[
                                    {
                                        label: translate('information'),
                                        content: (
                                            <TabContent>
                                                <UpdateTreadmillForm {...selectedTreadmill}
                                                                     loading={loading}
                                                                     canUpdate={(mode == 'my')}
                                                                     onUpdate={async d => {
                                                                         await dispatch(actions.updateTreadmill({
                                                                             ...selectedTreadmill,
                                                                             ...d
                                                                         }));
                                                                     }}/>
                                            </TabContent>
                                        )
                                    },
                                    (mode != 'my' && isModerator == false) ? null :
                                        {
                                            label: translate('editor'),
                                            content: (
                                                <div>
                                                    {isMobile() == true ?
                                                        <div style={{
                                                            witdh: '100%',
                                                            height: '100%', display: 'flex',
                                                            justifyContent: 'center', alignItems: 'center',
                                                            fontWeight: 'bold', fontSize: '30px'
                                                        }}>
                                                            <div>
                                                                {translate('edit_treadmill_computer_only')}
                                                            </div>
                                                        </div>

                                                        :

                                                        <TabContent style={{height: 'calc(100% - 50px)'}}>
                                                            <div style={{
                                                                height: `calc(100vh - 180px)`
                                                            }}>
                                                                <TreadmillEditor
                                                                    loading={loading}
                                                                    openPreview={(time) => {
                                                                        ls(`treadmill_preview_${selectedTreadmillId}`, time);
                                                                        setTabNumber(2);
                                                                    }}
                                                                    {...selectedTreadmill}
                                                                    onSave={async (arr, saved) => {
                                                                        await dispatch(actions.updateTreadmill({
                                                                            id: selectedTreadmill.id,
                                                                            items: arr
                                                                        }));
                                                                        if (saved === false) {
                                                                            toast('Сохранено');
                                                                        }
                                                                    }}/>
                                                            </div>
                                                        </TabContent>
                                                    }
                                                </div>
                                            )
                                        },
                                    {
                                        label: translate('preview'),
                                        content: (
                                            <div>
                                                {isMobile() == true ?
                                                    <div style={{
                                                        witdh: '100%',
                                                        height: '100%', display: 'flex',
                                                        justifyContent: 'center', alignItems: 'center',
                                                        fontWeight: 'bold', fontSize: '30px'
                                                    }}>
                                                        <div>
                                                            {translate('play_treadmill_computer_only')}
                                                        </div>
                                                    </div>

                                                    :

                                                    <TabContent className={'treadmill_placeholder'} style={{
                                                        height: 'calc(100% - 50px)',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}>

                                                        <div style={{
                                                            height: `calc(100vh - 220px)`
                                                        }}>
                                                            <TreadmillPlaceholder>
                                                                <SmartTreadmillPlayerTool
                                                                    loading={loading}
                                                                    isPreview={true}
                                                                    setCurrentSlide={val => {
                                                                        setCurrentSlideId(val);
                                                                    }}
                                                                    {...selectedTreadmill} />
                                                            </TreadmillPlaceholder>

                                                            <ShareExercisePlaceholder>
                                                                <EditPlaceholder onClick={() => {
                                                                    ls(`treadmill_slide_${selectedTreadmillId}`, currentSlideId);
                                                                    setTabNumber(1);
                                                                }}>
                                                                    <EditButton>
                                                                        {translate('edit_block')}
                                                                    </EditButton>
                                                                    <ShareImg src={edit_svg}/>
                                                                </EditPlaceholder>

                                                                <ShareLinkSpan onClick={() => {
                                                                    CommonHelper.linkTo(`/share/treadmills/${selectedTreadmill?.id}`, true);
                                                                }}>
                                                                    <ShareImg src={share_img}/>
                                                                    {translate('share_treadmills')}
                                                                </ShareLinkSpan>
                                                            </ShareExercisePlaceholder>

                                                        </div>
                                                    </TabContent>
                                                }
                                            </div>
                                        )
                                    },
                                    (mode != 'my' && isModerator == false) ? null :
                                        {
                                            label: translate('additional_settings'),
                                            content: (
                                                <TabContent style={{height: 'calc(100% - 50px)'}}>
                                                    <GreenButton style={{width: 'fit-content', marginTop: '20px'}}
                                                                 onClick={() => {
                                                                     // setAddModalVisible(false);
                                                                     setCopyModalVisible(true);
                                                                 }}>
                                                        {translate('create_copy')}
                                                    </GreenButton>

                                                    {currentUserId !== selectedTreadmill.creatorId ? null :
                                                        <RedButton style={{width: 'fit-content', marginTop: '20px'}}
                                                                   onClick={async () => {
                                                                       let deleteConfirm = window.confirm("Вы уверены, что хотите удалить тредмил?");
                                                                       if (deleteConfirm === true) {
                                                                           await dispatch(actions.deleteTreadmill(selectedTreadmillId));
                                                                           setSelectedTreadmillId(undefined);
                                                                       }
                                                                   }}>
                                                            {translate('delete')}
                                                        </RedButton>
                                                    }
                                                </TabContent>
                                            )
                                        }
                                ].filter(x => (x != undefined))}
                            />
                        </FullWindow>
                    }

                </NiceModal>
            }


            {addModalVisible == false ? null :
                <NiceModal width={720} onClose={() => {
                    setAddModalVisible(false);
                }}>

                    <ModalInner>
                        {/* <ModalInner style={{width: 720}}>  */}

                        <UpdateTreadmillForm name={''} loading={loading} onUpdate={async d => {
                            let pld = await dispatch(actions.createTreadmill({
                                ...d,
                                creatorId: currentUserId
                            }));
                            setAddModalVisible(false);
                            setSelectedTreadmillId(pld.treadmill.id);
                        }}/>

                    </ModalInner>

                </NiceModal>
            }

            {copyModalVisible === false ? null :
                <NiceModal onClose={() => {
                    setCopyModalVisible(false);
                }}>
                    <CopyModalInner>
                        <div>Введите название копии</div>
                        <Input placeholder="Введите название копии" value={copyName} onChange={(evt) => {
                            let s = evt.target.value;
                            setCopyName(s);
                        }}/>

                        <GreenButton onClick={async () => {
                            let dd = JSON.parse(JSON.stringify(selectedTreadmill));
                            delete dd.id;
                            delete dd.timestamp;
                            delete dd.updatedTimestamp;
                            dd.name = (copyName.trim() === '' || copyName === undefined) ? 'No name' : copyName;
                            let pld = await dispatch(actions.createTreadmill({
                                ...dd,
                                creatorId: currentUserId
                            }));
                            setSelectedTreadmillId(pld?.treadmill?.id);
                            setCopyModalVisible(false);
                        }}>
                            Создать копию
                        </GreenButton>
                    </CopyModalInner>
                </NiceModal>
            }

            {createFromCardsModalVisible == false ? null :
                <NiceModal onClose={() => {
                    setCreateFromCardsModalVisible(false);
                }}>
                    <CardModalInnerPlaceholder>
                        <AllDrochkaExercisesPanel
                            mode={'all'}
                        />
                    </CardModalInnerPlaceholder>

                </NiceModal>
            }

        </Wrapper>
    );
}

const SelectSpan = styled.span`
  cursor: pointer;
  font-size: 12px;
  opacity: 0.7;

  :hover {
    opacity: 1;
  }
`;

const SearchPlaceholder = styled.div`
  width: 240px;
`;

const FullWindow = styled.div`
  width: 1200px;
  height: calc(100vh - 120px);

  @media (max-width: 680px) {
    width: 95vw;
    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 0;
    }
  }
`

const Wrapper = styled.div`

`;

const TabContent = styled.div`
  width: 100%;
  padding-top: 5px;
`;

const TopPlaceholder = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const CreateTreadmillsButtons = styled.div`
  display: flex;
  gap: 20px;
`;

const Quarter = styled.div`
  display: inline-block;
  vertical-align: top;
  width: calc(25% - 10px);
  margin: 5px;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid whitesmoke;
  background-color: white;
  border-radius: 3px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }

  @media (max-width: 800px) {
    width: 50%;
  }
`;

const ModalInner = styled.div`
  width: 920px;
  box-sizing: border-box;
  padding: 5px;
  height: calc(100vh - 80px);
  max-height: calc(100vh - 80px);
  overflow-y: auto;

  @media (max-width: 720px) {
    width: 95vw;
  }
`;

const NamePlaceholder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
`;

const AvatarImage = styled.div`
  background-image: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: whitesmoke;
  height: 240px;
  width: 100%;
  margin-bottom: 5px;

  @media (max-width: 1100px) {
    height: 180px;
  }
`;

const Name = styled.div`
  font-weight: bold;
  font-size: 16px;
`;

const MineIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const ShareLinkSpan = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0.75;

  :hover {
    opacity: 1;
  }
`;

const ShareExercisePlaceholder = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 5px;
  margin-top: 10px;
`;

const EditPlaceholder = styled.div`
  display: flex;
  gap: 5px;
  cursor: pointer;
  user-select: none;
`;

const EditButton = styled.div`
  width: fit-content;
`;

const ShareImg = styled.img`
  margin-right: 5px;
  width: 16px;
  height: 16px;
`;

const TreadmillPlaceholder = styled.div`
  --h: calc(100vh - 250px);
  width: calc((var(--h) - 50px) * 720 / 470);
  max-width: 1152px;
  height: var(--h);
  max-height: 802px;

  @media (max-width: 1000px) {
    --w: calc(100vw - 100px);
    width: var(--w);
    height: calc((var(--w) * 470 / 720) + 50px);
  }
`;

const TreadmillControls = styled.div`
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
`;

const Slider = styled.input`
  width: 100%;
  margin: 10px 0;
  cursor: pointer;
  flex: 3;
  -webkit-appearance: none;
  //overflow: hidden;
  //height: 10px;

  :hover, :focus, :active {
    outline: none;
  }

  ::-webkit-slider-runnable-track {
    //height: 2px;
    //background-color: #ff9900;
  }

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    background-color: #FFA71B;
    width: 16px;
    height: 16px;
    border-radius: 12px;
    //box-shadow: -500px 0 0 500px dodgerblue;
  }

  ::-webkit-slider-container {
    -webkit-appearance: none;
    border-radius: 2px;
    background-color: #e1e1e1;
    height: 4px;
  }

  ::-ms-fill-lower {
    //background-color: #ff9900;
    //border-radius: 2px;
    //height: 4px;
  }
`;

const PauseButton = styled.img`
  cursor: pointer;
  opacity: 0.7;
  flex: 0;
  user-select: none;
  width: 24px;
  height: 24px;
  transform: scale(1.2);

  :hover {
    opacity: 1;
  }
`;

const CopyModalInner = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px 20px;
  font-size: 16px;
`;

const CardModalInnerPlaceholder = styled.div`
  width: calc(100vw - 400px);
  height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;
`;

const ToggleInput = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
  user-select: none;

  :checked + label {
    background: #ff951c;
  }

  :checked + label:after {
    left: calc(100% - 1px);
    transform: translateX(-100%);
  }
`;

const ToggleLabel = styled.label`
  cursor: pointer;
  text-indent: -9999px;
  width: 40px;
  height: 20px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
  user-select: none;

  :after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: 18px;
    height: 18px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
  }

  :active:after {
    width: 26px;
  }
`;